import React from 'react';
import iconassortment from '../../../images/icons/alerticons/icon-assortment.svg';
import iconmarketshare from '../../../images/icons/alerticons/icon-market-share.svg';
import iconmarkettrends from '../../../images/icons/alerticons/icon-market-trends.svg';
import iconpricing from '../../../images/icons/alerticons/icon-pricing.svg';
import iconpromotions from '../../../images/icons/alerticons/icon-promotions.svg';
import parse from 'react-html-parser';
import {
    formatPercent,
    toTitleCase,
    numberWithCommas,
    formatPercentWithSign,
    txtColor,
    valueCheck
} from '../utils/alertUtil1_2';
import { ByzzerButton } from '@byzzer/ui-components';

function AlertCardView1_2(props) {
    //console.log("Alert Map props in view 2", props.alertMap);
    let alert = props.CardData;
    const alertMap = props.alertMap;
    // let results = JSON.parse(alert.results);
    let results = alert.results;

    let alertResults = JSON.parse(JSON.stringify(results).replace(/Fmcg/g, 'FMCG'));
    let alertConfig = alert.actionConfig.insightParams ? alert.actionConfig.insightParams : alert.actionConfig;
    alertConfig.focus_brand = toTitleCase(alertConfig.focus_brand);
    alertConfig.category = toTitleCase(alertConfig.category);


    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'MARKET_SHARE') {
        alert.title = 'Market Share';
        alert.blurb = parse(
            `${alertConfig.focus_brand}'s share of ${alertConfig.category} is ${formatPercent(
                alertResults.share_of_category
            )}%, ${txtColor(`${alertResults.share_of_category_change_gain_or_decline} of ${formatPercent(
                alertResults.share_of_category_change
            )} points`, `${alertResults.share_of_category_change_gain_or_decline}`)}.`
        );
        alert.iconPath = iconmarketshare;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'COMPETITIVE_PRICING') {
        alert.title = 'Pricing';
        alert.blurb = parse(
            `${alertConfig.focus_brand}'s pricing position vs. competitors is ${formatPercentWithSign(alertResults.ratio_change)}%.`
        );
        alert.iconPath = iconpricing;
        //alert.blurb = `Total category average price is $${alertResults.data.category_average_price}, ${alertResults.data.category_price_change_direction} ${formatPercent(alertResults.data.category_average_price_ya_change_percent)}% vs. prior year.`
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'DISTRIBUTION_GAIN_AND_LOSSES') {
        alert.title = 'Distribution';
        //alert.blurb = `Category distributions are ${alertResults.data.category_tdp_direction} ${formatPercent(alertResults.data.category_tdps_change_ya)}% vs. prior year`;
        alert.blurb = parse(
            `${alertConfig.focus_brand}'s share of distribution ${txtColor(`${alertResults.share_of_tdp_diff_direction} by ${formatPercent(Math.abs(alertResults.share_of_tdp_diff))} share points`, `${alertResults.share_of_tdp_diff_direction}`)}.`
        );
        alert.iconPath = iconassortment;
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'PROMOTION_EFFICIENCY') {
        alert.title = 'Promotion Efficiency';
        alert.blurb = parse(
            `${alertConfig.focus_brand}'s promotion efficiency is $${alertResults.promotion_efficiency
            }, ${alertResults.change_promotion_efficiency_direction} ${txtColor(`${formatPercentWithSign(alertResults.change_promotion_efficiency)}%`, alertResults.change_promotion_efficiency)}.`
        );

        // ${formatPercent(
        //     Math.abs(alertResults.change_promotion_efficiency)
        // )}%`;
        alert.iconPath = iconpromotions;
        //alert.blurb = `Your promotion efficiency is ${alertResults.data.promotion_efficiency}( ${alertResults.data.change_promotion_efficiency_direction} ${alertResults.data.change_promotion_efficiency}% vs. prior year).`
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'ECOSYSTEM_ALERT') {
        alert.title = 'Category Performance';
        let alertResults_category_sales_change_display_market_performance = valueCheck(alertResults.category_sales_change_direction) ? ` ${formatPercent(alertResults.category_sales_change)}%` : '';
        alert.blurb = parse(
            `${toTitleCase(alertResults.category)} sales are $${numberWithCommas(
                alertResults.category_sales
            )}, ${txtColor(`${alertResults.category_sales_change_direction}${alertResults_category_sales_change_display_market_performance}`, `${alertResults.category_sales_change_direction}`)}.`
        );
        alert.iconPath = iconmarkettrends;
    }



    return (
        <>
            <div className="card">
                <div className="card_image">
                    <img src={alert.iconPath} alt={alert.title} />
                    {/* <img src={reportIcon} alt={item.title} />  */}
                </div>
                <h2 className="card_heading_report">{alert.title}</h2>
                <p className="card_blurb">{alert.blurb}</p>


                <ByzzerButton
                    label={'See More'}
                    onClick={() => {
                        props.handleClick(alert);
                    }}
                />
            </div>
            {/* )} */}

        </>
    );
}

export default AlertCardView1_2;
