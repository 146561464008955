import './MarketPicker.scss';
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {RootMarketNodes} from "@/types/ApiTypes"
import {isMarketDisabled, useMarketService} from "@/services/market.service";
import {ByzzerChangeEventHandler, ByzzerTipIcon} from "@byzzer/ui-components";
import {noop, uniq} from "lodash";
import {MarketPickerNode} from "./MarketPickerNode";
import {
    MarketPickerContext,
    MarketPickerContextValue
} from "./MarketPickerContext";
import { ReportDatatype, RunConfigMarket } from '@/types/ReportRun';
import { getRegexForSearchString } from '@/utils';
import { useUser } from '@/contexts/UserContext';
import { invertedMarketFolderLabelMapping, marketFolderLabelMapping } from '../DodConfigEditor/common/utils';

export type MarketPickerProps = {
    productSku?: string;
    className?: string;
    categories?: string[];
    maxSelections?: number;
    requireRemainingMarket?: boolean;
    requireRemainingMarketSuffix?: string;
    requiredMasterCompany?: string;
    requiredMarketGroup?: string;
    runType?: RunType;
    value?: RunConfigMarket[];
    name?: string;
    onChange?: ByzzerChangeEventHandler<RunConfigMarket[]>;
    hideTotalUSMarkets?: boolean;
    showRemainingMarkets?: boolean;
    hideDisabledMarkets?: boolean;
    hideNonPromoMarkets?: boolean;
    displayMarketGroup: ReportDatatype;
    includePseudoRemainingMarkets?: boolean;
    hideGeographicMarkets?: boolean;
    purchasedMarketKeys?: string[];
    isDodMarketPicker?: boolean;
    filterText?: string;
    hasSummedCategories?:boolean;
    summedCategories?:string[];
    nonSummedCategories?:string[];
    isMarketSummedMode?:boolean;
    enableParentMarket?: boolean;
    filterType?: string;
    updateAccessibleMarketFolders?: (folders) => void
};

const baseClassName = 'market-picker';
export function MarketPicker(
    {
        className,
        productSku,
        categories=[], // selected categories
        onChange,
        value,
        name,
        runType = 'subscription',
        maxSelections,
        requiredMasterCompany,
        requiredMarketGroup,
        requireRemainingMarket,
        requireRemainingMarketSuffix,
        hideTotalUSMarkets,
        showRemainingMarkets,
        hideDisabledMarkets,
        hideNonPromoMarkets,
        includePseudoRemainingMarkets = false,
        displayMarketGroup,
        hideGeographicMarkets = false,
        purchasedMarketKeys,
        isDodMarketPicker = false,
        hasSummedCategories = false,
        summedCategories,
        nonSummedCategories,
        filterText,
        isMarketSummedMode,
        enableParentMarket,
        updateAccessibleMarketFolders,
        filterType
    }: MarketPickerProps) {
    const {
        features,
        company,
        features: { enableLimitedMarketSubscription },
        accessibleMasterCompanies,
    } = useUser();
    const {
        getRootMarketNodes,
        getChildMarketNodes,
        getCachedMarketNodeByName,
        getAccessibleRootMarketNodes,
    } = useMarketService();
    const [rootMarketNodes, setRootMarketNodes] = useState<RootMarketNodes | undefined>();
    const [filteredRootMarketNodes, setFilteredRootMarketNodes] = useState<typeof rootMarketNodes>(rootMarketNodes);
    const [lastClickedPath, setLastClickedPath] = useState<string | null>(null);
    const [selectableMarkets, setSelectableMarkets] = useState<MarketNode[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    
    const [contextValue, setContextValue] = useState<MarketPickerContextValue>({
        addMarket: noop,
        removeMarket: noop,
        getChildMarketNodes(parent) {
            return getChildMarketNodes({
                parent,
                sku: productSku!,
                categories, //PR review candidate
                runType,
                purchasedMarketKeys,
                isDodMarketPicker,
                hasSummedCategories,
                summedCategories,
                nonSummedCategories,
                isMarketSummedMode,
                isEnableParentCompanyMode: enableParentMarket
            });
        },
        selectedMarkets: [],
        selectedMasterCompanies: [],
        maxSelections: Number(maxSelections) > 0 ? maxSelections : Infinity,
        showRemainingMarkets,
        hideDisabledMarkets,
        hideNonPromoMarkets,
        requireRemainingMarket,
        requireRemainingMarketSuffix,
        requiredMasterCompany,
        requiredMarketGroup,
        onShiftSelect: noop,
        enableParentMarket
    });

    // BYZ-12613 to avoid duplicate child markets being shown on ui, returned by getAccessibleMarketNodes() method below

    {
        /** BYZ-12613 little context here: in byzzer, when enableLimitedMarket flag is set to true, user will have access to only accessible markets
         *
         * getAccessibleRootMarketNodes() method used above, basically returns all accessible markets (including both parent and its child markets)
         * which the user has access to based on their category selection as an array of marketNodes
         *
         * since the api is designed in a way to return both parent and child markets, jsx was showing expand collapse icon for each parent market
         * which when expanded, again shows the child markets in addition to the child market already returned by the api
         *
         * Hence the child markets were getting duplicated all over the place for limited market user.
         *
         * since its purely a ui enhancement in MarketPicker component to display all markets in a flat list,
         * we format the data and render only the parent market node without expand/collapse icon
         * hence we pass each accessible marketNode through formatAccessibleMarket() method before rendering
         */
    }
    function formatAccessibleMarket(market: MarketNode): MarketNode {
        return {
            ...market,
            hasChildren: false
        }
    }

    useEffect(() => {
        setContextValue((contextValue) => ({
            ...contextValue,
            addMarket(market, remainingMarketName?: string) {
                const currentSelectedMarket = (value ?? [])?.find(
                    (currentMarket) => currentMarket?.path === market?.path
                );
                const otherSelectedMarkets = (value ?? [])?.filter(
                    (currentMarket) => currentMarket?.path !== market?.path
                );

                const remainingMarketRunConfig = getCachedMarketNodeByName(remainingMarketName);
                let remainingMarket: RunConfigMarket | undefined;

                let selectedMarkets: RunConfigMarket[] = [];
                const limit = Number(maxSelections) > 0 ? maxSelections : Infinity;
                let newMarket: RunConfigMarket = {
                    path: market.path,
                    key: market.key,
                    name: market.name,
                    selectionType: market?.selectionType,
                    masterCompany: market.masterCompany,
                    marketGroup: market.marketGroup,
                    marketHasPartialApproval: market.hasPartialApproval,
                    isParentCompany: market?.isParentCompany,
                    isOutletOfParentCompany: market?.isOutletOfParentCompany
                };

                if (remainingMarketRunConfig) {
                    remainingMarket = {
                        isRemaining: true,
                        path: remainingMarketRunConfig.path,
                        key: remainingMarketRunConfig.key,
                        name: remainingMarketRunConfig.name,
                        selectionType: remainingMarketRunConfig?.selectionType,
                        masterCompany: remainingMarketRunConfig.masterCompany,
                        marketGroup: remainingMarketRunConfig.marketGroup,
                        marketHasPartialApproval: market.hasPartialApproval,
                    };
                    (currentSelectedMarket ?? newMarket).remainingMarketRunConfig = remainingMarket; // TODO: for older reports in history without this new remainingMarketRunConfig value, see if i can create a function that takes the remaining market and looks up the necessary info to create it on the fly
                }

                if (limit === 1) {
                    // if only one market is allowed replace it,
                    selectedMarkets = [currentSelectedMarket ?? newMarket];
                } else {
                    // the slice ensures that only the max number of markets are ever set
                    // this could cause weird behavior when require remainingRemainingMarkets is enabled and
                    // maxMarkets is set to an odd number
                    selectedMarkets = [...otherSelectedMarkets, currentSelectedMarket ?? newMarket]
                        .filter(Boolean)
                        .slice(0, limit);
                }
                onChange?.({
                    name,
                    value: selectedMarkets,
                });
            },
            removeMarket(marketToRemove, includeRemaining?: boolean) {
                const selectedMarkets = (value ?? []).filter((market) => {
                    return (
                        market.name !== marketToRemove.name &&
                        (!includeRemaining || !marketToRemove.remainingMarketNames?.includes(market.name))
                    );
                });
                onChange?.({
                    name,
                    value: selectedMarkets,
                });
            },
            maxSelections: Number(maxSelections) > 0 ? maxSelections : Infinity,
            selectedMarkets: value ?? [],
            requiredMasterCompany,
            requiredMarketGroup,
            includePseudoRemainingMarkets,
            showRemainingMarkets,
            hideDisabledMarkets,
            hideNonPromoMarkets,
            onShiftSelect(currentSelectedPath: string){
                let marketData = selectableMarkets;
                const shouldUseAccessibleMarkets = features?.enableLimitedMarketSubscription;
                if (shouldUseAccessibleMarkets) {
                    marketData = filteredRootMarketNodes?.accessible || [];
                }
                let startIndex = marketData.findIndex(val => val.path === lastClickedPath);
                let endIndex = marketData.findIndex(val => val.path === currentSelectedPath);
                if(startIndex === -1 || endIndex === -1) return;
                let indices = [startIndex,endIndex];
                indices.sort((a,b) => a - b);
                [startIndex,endIndex] = indices;
                const otherSelectedMarkets = (value ?? [])?.filter(
                    (currentMarket) => !marketData?.slice(startIndex, endIndex + 1).some((childMarket) => childMarket.path === currentMarket.path )
                );
                const selectedMarkets = marketData?.slice(startIndex, endIndex + 1).reduce((accum: RunConfigMarket[], market) => {
                    let newMarket: RunConfigMarket = {
                        path: market.path,
                        key: market.key,
                        name: market.name,
                        selectionType: market?.selectionType,
                        masterCompany: market.masterCompany,
                        marketGroup: market.marketGroup,
                        marketHasPartialApproval: market.hasPartialApproval,
                        isParentCompany: market?.isParentCompany,
                        isOutletOfParentCompany: market?.isOutletOfParentCompany
                    };
                    if (
                        market.selectable && !isMarketDisabled(market, {
                            requiredMarketGroup,
                            requiredMasterCompany,
                            requireRemainingMarket,
                            enableLimitedMarketSubscription,
                            accessibleMasterCompanies,
                            reportType: displayMarketGroup,
                            purchasedMarketKeys: company?.purchasedMarketKeys,
                        }) && (showRemainingMarkets || !market.isRemaining)
                    ) {
                        return [...accum, newMarket];
                    }
                    return accum;
                }, [])
                setTimeout(() => {
                    onChange?.({
                        name,
                        value: uniq([...selectedMarkets, ...(otherSelectedMarkets ?? [])]),
                    });
                }, 100);
            }
        }));
    }, [
        maxSelections,
        value,
        requiredMasterCompany,
        requiredMarketGroup,
        showRemainingMarkets,
        hideDisabledMarkets,
        hideNonPromoMarkets,
    ]);

    useEffect(() => {
        setContextValue((value) => ({
            ...value,
            getChildMarketNodes(parent) {
                return getChildMarketNodes({
                    parent,
                    sku: productSku!,
                    categories, //PR review candidate
                    runType,
                    purchasedMarketKeys,
                    isDodMarketPicker,
                    hasSummedCategories,    
                    summedCategories,  
                    nonSummedCategories,  
                    isMarketSummedMode,
                    isEnableParentCompanyMode: enableParentMarket            
                });
            },
        }))
        if (!productSku) return;

        (async () => {
            try {
                let rootMarkets: RootMarketNodes;
                const shouldUseAccessibleMarkets = features?.enableLimitedMarketSubscription;
                const rootMarketKeyOrder: Partial<keyof RootMarketNodes>[] = ['totalUs', 'fmcgRetailers', 'specialityRetailers', 'geographies'];
                let allMarkets: MarketNode[] = [];
                if (shouldUseAccessibleMarkets) {
                    setLoading(true)
                    rootMarkets = await getAccessibleRootMarketNodes({sku: productSku, categories, runType, purchasedMarketKeys});
                    
                    allMarkets = rootMarkets?.accessible ?? [];
                    // Extract unique sub_market_types (folder names)
                    const uniqueSubMarketTypes = [
                        ...new Set(
                            allMarkets
                                .map((market) => market.subMrktType)
                                .filter(Boolean)
                        ),
                    ];

                    // Build accessible market folders
                    const accessibleMarketFolders = uniqueSubMarketTypes.map((subMarketType) => {
                        const mappedKey = marketFolderLabelMapping[subMarketType] || subMarketType;
                        const formattedLabel = subMarketType.charAt(0).toUpperCase() + subMarketType.slice(1) + 's';
                        
                        return {
                            key: mappedKey,
                            label: formattedLabel,
                            value: mappedKey,
                        };
                    });
                    updateAccessibleMarketFolders?.([
                        {
                            key: 'all',
                            label: 'All',
                            value: 'all',
                        }, ...accessibleMarketFolders])
                } else {
                    rootMarkets = await getRootMarketNodes({
                        sku: productSku,
                        categories,
                        runType,
                        purchasedMarketKeys,
                        isDodMarketPicker,
                        hasSummedCategories,
                        summedCategories,
                        nonSummedCategories,
                        isMarketSummedMode,
                    });
                    allMarkets = rootMarketKeyOrder.flatMap(key => rootMarkets?.[key] ?? []);
                }
                setSelectableMarkets(allMarkets);
                setRootMarketNodes(rootMarkets);
            } catch(err) {
                // TBD
                console.error(err);
            }finally {
                setLoading(false);
            }
        })();

    }, [productSku, categories.join(',')]);

    useEffect(() => {
        if (rootMarketNodes) {
            const shouldUseAccessibleMarkets = features?.enableLimitedMarketSubscription;
            let filteredMarkets: Partial<Record<keyof RootMarketNodes, MarketNode[]>> = { ...rootMarketNodes };
            if (shouldUseAccessibleMarkets && filterType && filterType !== 'all') {
                filteredMarkets = filterMarketsByType(filteredMarkets, filterType);
            }
            if (filterText) {
                filteredMarkets = filterMarketsByText(filteredMarkets, filterText);
            }
            setFilteredRootMarketNodes(filteredMarkets);
        }
    }, [rootMarketNodes, filterText, filterType, features?.enableLimitedMarketSubscription]);

    // Filtering Functions
    const filterMarketsByType = (
        markets: Partial<Record<keyof RootMarketNodes, MarketNode[]>>,
        filterType: string
    ): Partial<Record<keyof RootMarketNodes, MarketNode[]>> => {
        return Object.entries(markets).reduce((acc, [key, value]) => {
            if (value) {
                const filteredByFolder = value.filter(
                    (market) => market.subMrktType === invertedMarketFolderLabelMapping[filterType]
                );
                if (filteredByFolder.length > 0) {
                    acc[key as keyof RootMarketNodes] = filteredByFolder;
                }
            }
            return acc;
        }, {} as Partial<Record<keyof RootMarketNodes, MarketNode[]>>);
    };

    const filterMarketsByText = (
        markets: Partial<Record<keyof RootMarketNodes, MarketNode[]>>,
        filterText: string
    ): Partial<Record<keyof RootMarketNodes, MarketNode[]>> => {
        const matcher = getRegexForSearchString(filterText);
        return Object.entries(markets).reduce((acc, [key, value]) => {
            if (value) {
                const filteredByText = value.filter(({ name }) =>
                    matcher.test(name.trim())
                );
                if (filteredByText.length > 0) {
                    acc[key as keyof RootMarketNodes] = filteredByText;
                }
            }
            return acc;
        }, {} as Partial<Record<keyof RootMarketNodes, MarketNode[]>>);
    };
    return (
        <div className={classnames(baseClassName, className)}>
            <MarketPickerContext.Provider value={{...contextValue, setLastClickedPath,
        lastClickedPath, setSelectableMarkets, selectableMarkets}}>
                {loading && <div className={`${baseClassName}__loading`}>Loading Markets</div>}

                {displayMarketGroup === 'cps' && (
                    <>
                        {filteredRootMarketNodes?.accessible?.map(formatAccessibleMarket).map((market) => (
                            <MarketPickerNode
                                key={market.path}
                                marketNode={market}
                                includeToggle={market.hasChildren}
                                includeSelector={market.selectable}                                
                                displayMarketGroup={displayMarketGroup} // was removed previously after being added.  See BYZ-12783 and BYZ-12921
                            />
                        ))}
                        {Boolean(filteredRootMarketNodes?.panelTotal?.length) && (
                            <h2 className={`${baseClassName}__market-group-title`}>Total US Markets</h2>
                        )}
                        {filteredRootMarketNodes?.panelTotal?.map((market) => (
                            <MarketPickerNode
                                key={market.path}
                                marketNode={market}
                                includeToggle={market.hasChildren}
                                includeSelector={market.selectable}
                                displayMarketGroup={displayMarketGroup}
                            />
                        ))}
                        {Boolean(filteredRootMarketNodes?.panelChannels?.length) && (
                            <h2 className={`${baseClassName}__market-group-title`}>Outlets By Channel</h2>
                        )}
                        {filteredRootMarketNodes?.panelChannels?.map((market) => (
                            <>
                                <MarketPickerNode
                                    key={market.path}
                                    marketNode={market}
                                    includeToggle={market.hasChildren}
                                    includeSelector={market.selectable}
                                    displayMarketGroup={displayMarketGroup}
                                />
                            </>
                        ))}
                    </>
                )}

                {displayMarketGroup === 'rms' && (
                    <>
                        {filteredRootMarketNodes?.accessible?.map(formatAccessibleMarket).map((market) => (
                            <MarketPickerNode
                                key={market.path}
                                marketNode={market}
                                includeToggle={market.hasChildren}
                                includeSelector={market.selectable}
                                displayMarketGroup={displayMarketGroup}
                            />
                        ))}
                        {hideTotalUSMarkets !== true && (
                            <>
                                {Boolean(filteredRootMarketNodes?.totalUs?.length) && (
                                    <h2 className={`${baseClassName}__market-group-title`}>Total US Markets</h2>
                                )}
                                {filteredRootMarketNodes?.totalUs?.map((market) => (
                                    <MarketPickerNode
                                        key={market.path}
                                        marketNode={market}
                                        includeToggle={market.hasChildren}
                                        includeSelector={market.selectable}
                                        displayMarketGroup={displayMarketGroup}
                                    />
                                ))}
                            </>
                        )}

                        {Boolean(filteredRootMarketNodes?.fmcgRetailers?.length) && (
                            <h2 className={`${baseClassName}__market-group-title`}>FMCG Retailers By Channel</h2>
                        )}
                        {filteredRootMarketNodes?.fmcgRetailers?.map((market) => (
                            <MarketPickerNode
                                key={market.path}
                                marketNode={market}
                                includeToggle={market.hasChildren}
                                includeSelector={market.selectable}
                                displayMarketGroup={displayMarketGroup}
                            />
                        ))}
                        {Boolean(filteredRootMarketNodes?.specialityRetailers?.length) && (
                            <h2 className={`${baseClassName}__market-group-title`}>Specialty Retailers By Channel</h2>
                        )}
                        {filteredRootMarketNodes?.specialityRetailers?.map((market) => (
                            <MarketPickerNode
                                key={market.path}
                                marketNode={market}
                                includeToggle={market.hasChildren}
                                includeSelector={market.selectable}
                                displayMarketGroup={displayMarketGroup}
                            />
                        ))}

                        {hideGeographicMarkets !== true && (
                          <>
                            {Boolean(filteredRootMarketNodes?.geographies?.length) && (
                                <h2 className={`${baseClassName}__market-group-title`}>Geographies</h2>
                            )}
                            {filteredRootMarketNodes?.geographies?.map((market) => (
                                <MarketPickerNode
                                    key={market.path}
                                    marketNode={market}
                                    includeToggle={market.hasChildren}
                                    includeSelector={market.selectable}
                                    displayMarketGroup={displayMarketGroup}
                                />
                            ))}
                          </>
                        )}
                    </>
                )}

                {displayMarketGroup === 'omni' && <></>}
            </MarketPickerContext.Provider>
        </div>
    );
}

export default MarketPicker;
