import React, { useState, useCallback, useEffect } from "react";
import { TipIcon } from "@/components/icons";
import {useTenantApi} from "@/hooks/useTenantApi";
import { validate as isValidEmail } from "email-validator";
import { openErrorModal, confirm, alert } from "@/components/form/ByzzerModal";
import { create as createModal } from "react-modal-promise";
import classNames from "classnames";
import ByzzerModal2 from "@/components/modals/ByzzerModal2";
import { openTeamRoleInfo } from "@/components/TeamManager/TeamRoleInfo";
import { openTeamInfo } from "@/components/TeamManager/TeamInfo";
import { openDomainMismatchWarning } from "./DomainMismatchWarning";
import { TrackClick } from '@/analytics';
import { DomainMismatchReasonCode, TeamMember, TeamRole, UserInvite } from "@/types/InvitationTypes";
import { ByzzerTextInput, ByzzerSelect, ByzzerButton, ByzzerSelectOption } from "@byzzer/ui-components";
import { ApprovalCheckType } from "@/types/ApiTypes";

const teamRoleOptions: ByzzerSelectOption<any, TeamRole>[] = [
    { value: 'admin', display: 'Admin' },
    { value: 'user', display: 'User' },
    { value: 'viewer', display: 'Viewer' }
];

const baseClassName = 'subscription-users';

const onlyViewerRole: ByzzerSelectOption<any, Extract<TeamRole, 'viewer'>>[] = [{ value: 'viewer', display: 'Viewer' }];

const domainInUseApprovalMessage = {
    title: 'Domain already associated with another account',
    content: <>
        <p>The member you've invited has an email domain that is already in use by another Byzzer account. Because their domain is associated with another company, this invitation will require approval by the NielsenIQ team</p>
        <br />
        <p>Please tell us a little more about this user.</p>
    </>
};

const multiTenancyApprovalMessage = {
    title: 'Different Domain',
    content: <>
        <p>The member you've invited has an email domain that is different from the domain approved for your account. This invitation will require approval by the NielsenIQ team.</p>
        <br />
        <p>Please tell us a little more about this user.</p>
    </>
};

const duplicateInfoMessage = (
    <>
        <p>
            The user you're trying to invite already has access through another account. Don't worry - that doesn't
            mean they're working for someone else! You may have both signed up separately, and created two accounts
            for your company.
        </p>
        <p>For help inviting this user, reach out to support through the Chat.</p>
    </>
);

type InvitationEditorProps = {
    teamOptions: ByzzerSelectOption<any, number>[];
    availableUserCredits: number;
    onResolve: (result?: any) => void;
    members: TeamMember[];
}

type DomainMismatchResponse = {
    type: "domainMismatch",
    data: {
        domainMismatchReasonCode: DomainMismatchReasonCode;
        invitationExplanation: string;
    }
} 

export function InvitationEditor({ 
    teamOptions, 
    availableUserCredits, 
    onResolve, 
    members, 
    ...props 
}: InvitationEditorProps) {
    const { inviteUser, getInvitationNeedsApproval } = useTenantApi();
    const [busy, setBusy] = useState(false)
    const [invitee, setInvitee] = useState<UserInvite>({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        teamId: undefined
    });
    const [emailDomainDetails, setEmailDomainDetails] = useState<{
        email: string; 
        response: {
            reason: ApprovalCheckType;
            domainMatchedCompanies?: string[];
            showDomainMismatchPrompt?: boolean;
        }
    }>();

    const minimumInputLength = 5;

    async function onTeamRoleInfo() {
        openTeamRoleInfo()
    }

    async function onTeamInfo() {
        const result = await openTeamInfo()
        if (result.function === "redirect") {
            onCloseClick();
        }
    }

    const CannotInviteUserMessage = useCallback(() => {
        let message: string = '';

        const {email} = invitee;

        if (email.length <= minimumInputLength) {
        } else if (!isValidEmail(email)) {
            // not valid email
            message = 'Email address is not valid.';
        } else if (members?.map(mbr => mbr?.email)?.includes(email)) {
            // member or invite exists already
            message = 'This email is associated with an existing user or invited user.';
        }
        return <span className={classNames(`${baseClassName}__cannot-add-user`)}>{message}</span>;
    }, [invitee.email, members]);

    const sendUserInvitation = async () => {

        if (!canSubmit() || !emailDomainDetails?.response) return;

        const { 
            reason, 
            showDomainMismatchPrompt 
        } = emailDomainDetails?.response;

        let invitees = [invitee];
        let domainMismatchInvitees = [...invitees];

        switch (reason!) {
            case 'can_invite_normally': {
                setBusy(true);
                const invitationResult = await inviteUser({invitee});
                setBusy(false);
                onResolve(invitationResult);
                break;
            }
            case 'already_added': {
                await openErrorModal({
                    title: 'That user already exists',
                    content: duplicateInfoMessage
                });
                onResolve(false);
                break;
            }
            case 'not_allowed': {
                await alert({
                    title: `Attention`,
                    content: `The following user cannot be invited to this company: ${invitee.email}`
                });
                onResolve(false);
                break;
            }
            case 'approval_required_multi_tenancy': {
                if (showDomainMismatchPrompt) {
                    // @ts-ignore
                    const domainMismatchResponse: DomainMismatchResponse = await openDomainMismatchWarning({ invitees, onResolve, domainMismatchInvitees, title: multiTenancyApprovalMessage.title, content: multiTenancyApprovalMessage.content, requireTextDetails: true })
                    if (domainMismatchResponse) {
                        const { domainMismatchReasonCode, invitationExplanation } = domainMismatchResponse?.data ?? {};
                        setBusy(true);
                        const invitationResult = await inviteUser({
                            invitee, 
                            approvalNeededReasonCode: 'multi_company',
                            domainMismatchReasonCode,
                            invitationExplanation,
                            domainMatchedCompanies: emailDomainDetails?.response?.domainMatchedCompanies
                        });
                        onResolve(invitationResult)
                        setBusy(false);
                    }
                }
                break;
            }
            case 'approval_required_domain_in_use': {
                if (showDomainMismatchPrompt) {
                    // @ts-ignore
                    const domainMismatchResponse: DomainMismatchResponse = await openDomainMismatchWarning({ invitees, onResolve, domainMismatchInvitees, title: domainInUseApprovalMessage.title, content: domainInUseApprovalMessage.content, requireTextDetails: true })
                    if (domainMismatchResponse) {
                        const { domainMismatchReasonCode, invitationExplanation } = domainMismatchResponse?.data ?? {};
                        setBusy(true);
                        const invitationResult = await inviteUser({
                            invitee, 
                            approvalNeededReasonCode: 'domain_match',
                            domainMismatchReasonCode,
                            invitationExplanation,
                            domainMatchedCompanies: emailDomainDetails?.response?.domainMatchedCompanies
                        });
                        onResolve(invitationResult)
                        setBusy(false);
                    }
                }
                break;
            }
            case 'domain_mismatch_prompt': {
                // @ts-ignore
                const domainMismatchResponse: DomainMismatchResponse = await openDomainMismatchWarning({ invitees, onResolve, domainMismatchInvitees })
                if (domainMismatchResponse) {
                    setBusy(true);
                    const invitationResult = await inviteUser({invitee, domainMismatchReasonCode: domainMismatchResponse.data.domainMismatchReasonCode});
                    onResolve(invitationResult)
                    setBusy(false);
                    break;
                } else {
                    break;
                }
            }
            case 'consultant_prompt': {
                setBusy(false)
                if (await confirm({
                    title: 'Attention: Third-Party Advisor Access',
                    content: 'Looks like the user you are trying to add is a Byzzer approved third party advisor. To authorize their access please use the "Add a Third party" Option',
                    yesLabel: 'Add A Third Party',
                    noLabel: 'Cancel',
                })) {
                    const { role, email, teamId } = invitee;
                    onResolve({ 
                        type: 'consultant',
                        data: {
                            role,
                            email,
                            teamId,
                        }
                    })
                    break;
                } else {
                    break;
                };
            }
            default: {
                await confirm({
                    title: 'Attention',
                    content: `${invitee.firstName} ${invitee.lastName} (${invitee.email}) cannot be invited to this company - (${reason})`,
                    yesLabel: 'Ok',
                    noLabel: 'Cancel',
                })
            }
        }
    }

    async function handleSendClick() {
        await sendUserInvitation();
    }

    async function handleCancelClick() {
        onResolve?.(false)
    }

    function canSubmit() {
        const {firstName, lastName, email, teamId, role} = invitee;
        return Boolean(firstName?.trim()
            && lastName?.trim()
            && isValidEmail(email)
            && teamId
            && role?.toLowerCase()
            && !members?.map((mbr) => mbr?.email)?.includes(email)
        );
    }

    async function handleEmailBlur({ target }: React.FocusEvent<HTMLInputElement, Element>) {
        const {name, value: providedEmailAddress} = target;
        const userExistsAlready = members?.some(mbr => mbr?.email.trim().toLowerCase() === providedEmailAddress.trim().toLowerCase());

        if (Boolean(providedEmailAddress) && !userExistsAlready && isValidEmail(providedEmailAddress) && providedEmailAddress !== emailDomainDetails?.email) {
            setBusy(true);
            const approvalCheckResponse = await getInvitationNeedsApproval(providedEmailAddress);
            setEmailDomainDetails({
                email: providedEmailAddress,
                response: {
                    ...approvalCheckResponse
                }
            });
            setBusy(false);
        }
    }

    function handleInviteTextInput(event: ByzzerChangeEvent<string>) {
        const name = event.name;
        const value = event.value;

        if (!name) {
            console.warn(`InvitationEditor - updateUser fired (missing name on event) - 'event' ===>>`, event);
            return;
        };

        setInvitee(current => ({
            ...current,
            [name]: value,
        }));
    }

    function handleRoleChange(selectedRoleOption: typeof teamRoleOptions[number]) {
        const value = selectedRoleOption.value;
        setInvitee(current => ({
            ...current,
            role: value
        }));
    }

    function handleTeamChange(selectedTeam: ByzzerSelectOption<any, number>) {
        const value = selectedTeam.value;
        setInvitee(current => ({
            ...current,
            teamId: value
        }));
    }

    function onCloseClick() {
        onResolve(false);
    }

    return (
        <>
            {/* @ts-ignore */}
            <ByzzerModal2 className={classNames(`${baseClassName}__member-editor`)}>
                {/* @ts-ignore */}
                <ByzzerModal2.Header className={classNames(`${baseClassName}__modal-title`)} onClose={onCloseClick}>
                    Add Member
                </ByzzerModal2.Header>
                {/* @ts-ignore */}
                <ByzzerModal2.Content>
                    <ByzzerTextInput
                        value={invitee.firstName}
                        onChange={handleInviteTextInput}
                        name={"firstName"}
                        placeholder={"First Name"}
                        className={classNames(`${baseClassName}__modal-add-item-input`)}
                        label={"First Name"}
                    />
                    <ByzzerTextInput
                        value={invitee.lastName}
                        onChange={handleInviteTextInput}
                        name={"lastName"}
                        placeholder={"Last Name"}
                        className={classNames(`${baseClassName}__modal-add-item-input`)}
                        label={"Last Name"}
                    />
                    <ByzzerTextInput
                        value={invitee.email}
                        onChange={handleInviteTextInput}
                        name={"email"}
                        placeholder={'first.last@email.com'}
                        className={classNames(`${baseClassName}__modal-add-item-input`)}
                        label={<span>Email <CannotInviteUserMessage/></span>}
                        onBlur={handleEmailBlur}
                    />
                    <div className="member-editor__form-group">
                        {/* @ts-ignore */}
                        <h2>Member Type <TipIcon onClick={onTeamRoleInfo}/></h2>
                        {/* @ts-ignore */}
                        <ByzzerSelect
                            className={classNames(`${baseClassName}__modal-add-item-input`)}
                            name={'role'}
                            value={invitee.role}
                            // @ts-ignore
                            onChange={handleRoleChange}
                            placeholder={'Member Type'}
                            options={availableUserCredits < 1 ? onlyViewerRole : teamRoleOptions} 
                        />
                    </div>
                    <div className="member-editor__form-group">
                        {/* @ts-ignore */}
                        <h2>Choose a Team <TipIcon onClick={onTeamInfo}></TipIcon></h2>
                        {/* @ts-ignore */}
                        <ByzzerSelect
                            className={classNames(`${baseClassName}__modal-add-item-input`)}
                            name={'team'}
                            value={invitee?.teamId}
                            // @ts-ignore
                            onChange={handleTeamChange}
                            placeholder={'Choose a Team'}
                            // @ts-ignore
                            options={teamOptions} 
                        />
                    </div>
                </ByzzerModal2.Content>
                <ByzzerModal2.Footer className={classNames(`${baseClassName}__modal-footer`)}>
                    <TrackClick name={'Sub Members Add Members Invite clicked'}>
                        <ByzzerButton
                            className={classNames(`${baseClassName}__modal-footer-cancel`)}
                            type={"negative"}
                            onClick={handleCancelClick}
                            label={"Cancel"}
                            disabled={busy}
                        />
                    </TrackClick>
                    <ByzzerButton
                        label={emailDomainDetails?.response?.reason?.startsWith('approval') ? 'Request Invite' : 'Send Invite'}
                        disabled={!canSubmit() || busy}
                        className={classNames(`${baseClassName}__modal-footer-invite`)}
                        onClick={handleSendClick}
                        busy={busy}
                    />
                </ByzzerModal2.Footer>
            </ByzzerModal2>
        </>
    );
}
// @ts-ignore
export const openInvitationEditor = createModal(InvitationEditor);