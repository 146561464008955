import './MarketRunConfigFilters.scss';
import React, {forwardRef, ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState, ChangeEvent} from "react";
import classnames from "classnames";
import ByzzerMarketSearch from "@/components/MarketSelection/ByzzerMarketSearch/ByzzerMarketSearch";
import {ReportRunConfig, RunConfigMarket} from "@/types/ReportRun";
import {
    ReportRunConfigWizardContext,
    ReportRunWizardState
} from "@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard/ReportRunConfigWizardContext";
import {MarketRunConfigOptions} from "@/types/RunConfigOptions";
import {FilterGroup, FilterSection} from '@/components/ConfigurationEditors/FilterGroup';
import OmniMarketSelect from '@/components/OmniMarketSelect/OmniMarketSelect';
import {MarketPicker} from "@/components/MarketPicker";
import {ByzzerInlineSelect, WizardStepContext, ByzzerTipIcon} from "@byzzer/ui-components";
import {LimitedLabel} from "@/components/LimitedLabel";
import {MarketTypeSelector} from '@/components/MarketSelection/MarketTypeSelector';
import {useMarketService} from "@/services/market.service";
import {useUser} from "@/contexts/UserContext";
import {LimitedMarketSelect} from "@/components/LimitedMarketSelect";
import {ByzzerSwitch} from "@/components/form";
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo';

export type MarketRunConfigFiltersValue = Partial<ReportRunConfig>;

export type MarketRunConfigFiltersProps =
    {
        name?: string;
        onValidityChange?: (e: ByzzerValidityChangeEvent) => void;
        /**
         * Used when MarketRunConfigFilters is used as controlled component.  Should be used along with the value prop.
         * This prop should be omitted when used within a ReportRunConfigWizardContext because the value in the context will
         * always take precedence.
         */
        onChange?: (e: ByzzerChangeEvent<MarketRunConfigFiltersValue>) => void;
        /**
         * Used when MarketRunConfigFilters is used as controlled component.  Should be used along with the onChange prop.
         */
        value?: MarketRunConfigFiltersValue;
        summary?: ReactNode;
        datatype?: any;
    }
    & Partial<Omit<MarketRunConfigOptions, 'title' | 'type'>>
    & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export type MarketRunConfigFiltersRef = {
    value: MarketRunConfigFiltersValue;
    isValid: boolean;
};

const baseClassName = 'market-report-run-filters';

export const MarketRunConfigFilters = forwardRef<MarketRunConfigFiltersRef, MarketRunConfigFiltersProps>((
    {
        className,
        value,
        name,
        onChange,
        onValidityChange,
        datatype,
        maxMarkets = Infinity,
        limitMarketsToParentCompany,
        allowMarketTypeSelection,
        includeTotalUSMarkets = false,
        includeFmcgRetailers = false,
        includeSpecialityRetailers = false,
        includeGeographyMarkets = false,
        includePanelTotal = false,
        includePanelChannel = false,
        includeOmniTotalMarkets = false,
        includeOmniSubMarkets = false,
        excludeRemainingMarkets = false,
        requireRemainingMarket = false,
        /* BYZ-8104- we have added "hideTotalUSMarkets" flag to alby to hide and show the total US markets but at the same time we can use them as comparitive market if main market has US market as remaning or comparitive market.
         the same flag has been added in market.services to restrict the user from Total US markets search and market picker to wrap total US market tree with "hideTotalUSMarkets" flag. */
        hideTotalUSMarkets = false,

        requireTotalUSMarkets = false,
        requireFmcgRetailers = false,
        requireSpecialityRetailers = false,
        requireGeographyMarkets = false,
        requirePanelTotal = false,
        requirePanelChannel = false,
        requireOmniTotalMarkets = false,
        requireOmniSubMarkets = false,

        maxOmniTotalMarkets,
        maxOmniSubMarkets,
        excludeFocusMaket = false,
        marketTypeOptions, 
        ...props
    }, ref) => {

    const { company, features: userFeatures = {} } = useUser();
    const {value: contextValue, onChange: onContextChange, sku, runType} = useContext(ReportRunConfigWizardContext);
    const {showMessage, hideMessage} = useContext(WizardStepContext);
    const [internalValue, setInternalValue] = useState<MarketRunConfigFiltersValue>({});
    const [state, setState] = useState<ReportRunWizardState>({
        enableMarketTypePicker: allowMarketTypeSelection && Boolean(contextValue.markets?.length) ? false : allowMarketTypeSelection ? true : false,
        showRemainingMarkets: false
    });
    const wasValid = useRef<boolean>(false);
    const prevMarketsRef = useRef<RunConfigMarket[]>([]);
    const isOmni = datatype === 'omni';
    const isRms = datatype === 'rms';
    const isCps = datatype === 'cps';

    // todo: maybe this should be flag defined in Alby (but then again, maybe not)
    const limitMarketsToSameGroup = isCps;
    const showMarketTree = (isCps || isRms) && !state.enableMarketTypePicker;
    const showMarketSearch = showMarketTree;
    const showMarketTypePickers = state.enableMarketTypePicker;
    const categoriesRefreshKey = `${contextValue.categories?.sort().join()}`

    useEffect(() => {

        const isValid = checkValidity();

        if (wasValid.current !== isValid) {
            onValidityChange?.({
                name,
                isValid
            });

            wasValid.current = isValid;
        }

    }, [internalValue.markets, internalValue.comparisonMarket, internalValue.subMarkets]);

    // this could be problematic and lead to issues where the context and explicit value collide.
    // todo: find a clear way to choose which takes precedence context or value
    useEffect(() => {
        setInternalValue(contextValue ?? {})
    }, [contextValue]);

    useEffect(() => {
        if (value) {
            setInternalValue(value ?? {});
        }
    }, [value]);

    useEffect(() => {

        const firstMarket = contextValue.markets?.[0];
        const requiredMasterCompany = limitMarketsToParentCompany ? firstMarket?.masterCompany : undefined;
        const requiredMarketGroup = limitMarketsToSameGroup ? firstMarket?.marketGroup : undefined;
        const isMarketsChanged = JSON.stringify(contextValue.markets) !== JSON.stringify(prevMarketsRef.current);

        setState(state => ({
            ...state,
            requiredMasterCompany,
            requiredMarketGroup,
        }));
        
        dispatchParentCompaniesInContext();
        
        if (isMarketsChanged) {
            prevMarketsRef.current = contextValue.markets ?? []; // Update previous markets
            filterDisabledMarketsInContext();
        }
    }, [contextValue.markets]);

    /**
     * This purpose of this function is related to BYZ-10500.
     * When there is market selected which is a custom market means the market having isParentCompany
     * is true then have to create a parentCompany array which will contain all the selected market names
     * so that we can send it as a payload in validate API.
     */
    function dispatchParentCompaniesInContext() {
        const parentCompanies: string[] = [];
        let hasParentCompany: boolean = false;
    
        if (Array.isArray(contextValue.markets)) {
            for (const mrkt of contextValue.markets) {
                if (mrkt.isParentCompany) {
                    hasParentCompany = true;
                }
                parentCompanies.push(mrkt.name);
            }
        }
    
        if (hasParentCompany) {
            onContextChange?.('parentCompany', parentCompanies);
        } else {
            onContextChange?.('parentCompany', []);
        }
    }

    function filterDisabledMarketsInContext() {
        onContextChange?.('markets', contextValue.markets?.filter(mrkt => !mrkt.disabledMarketNode) ?? []);
    }

    function handleChange(e: ByzzerChangeEvent<any>) {
        onContextChange?.(e.name as keyof ReportRunConfig, e.value, e.data);       
    }

    function handleMarketTypeChange(e: ByzzerChangeEvent<boolean>) {

        onContextChange?.('markets', [], '');
        onContextChange?.('comparisonMarket', undefined, '');
        setState(state => ({
            ...state,
            [e.name!]: e.value
        }))
    }

    function checkValidity(): boolean {
        const hasMarketType = Boolean(internalValue.comparisonMarket?.channel?.length);
        const hasMarkets = Boolean(internalValue.markets?.length);
        const hasSubMarkets = Boolean(internalValue.subMarkets?.length);
        const requiresMarketType = state.enableMarketTypePicker;
        const requiresStandardMarkets = (isRms || isCps) && !state.enableMarketTypePicker
        const requiresMarkets = requiresStandardMarkets || requireOmniTotalMarkets;

        // Remaining markets are now paired/bundled with their base market.  Reports from history don't need to be checked.

        // @ts-ignore
        return (
            (!requiresMarketType || hasMarketType) &&
            (!requiresMarkets || hasMarkets) &&
            (!requireOmniSubMarkets || hasSubMarkets)
        );
    }

    function handleIncludeRemainingChange(e: ChangeEvent<HTMLInputElement>) {
        setState(state => ({
            ...state,
            showRemainingMarkets: e.target.checked
        }))
    }

    useImperativeHandle(ref, () => ({
        get value() {
            return internalValue;
        },
        get isValid() {
            return checkValidity();
        },
    }));

    return (
        <FilterGroup className={classnames(baseClassName, className)}>
            <FilterSection onlyRenderIf={isCps || isRms}>
                {Boolean(allowMarketTypeSelection) && (<>
                    <h1>What type of comparison is right for you?</h1>
                    {!(Boolean(excludeFocusMaket)) && <h3>We will show you your top 20 markets based on the analysis type you select or &nbsp;
                        <ByzzerInlineSelect<boolean>
                            name={'enableMarketTypePicker'}
                            onChange={handleMarketTypeChange}
                            value={state.enableMarketTypePicker}
                            options={[
                                {display: 'select by market type', value: true},
                                {display: 'select a custom list of markets', value: false}
                            ]}
                        />
                    </h3>}
                </>)}

                {!allowMarketTypeSelection && !requireRemainingMarket && (
                    <SelectorLabelInfo sku={sku as string} selectorCode={'focusMarkets'} max={maxMarkets} isLabelBold={true}/>
                )}

                {requireRemainingMarket && (
                    <SelectorLabelInfo sku={sku as string} selectorCode={'remainingMarkets'} max={maxMarkets} isLabelBold={true}/>
                )}

                {showMarketTypePickers && (
                    <MarketTypeSelector
                        name='comparisonMarket'
                        value={internalValue.comparisonMarket}
                        onChange={handleChange}
                        sku={sku}
                        marketTypeOptions={marketTypeOptions}
                    />
                )}

                {/*{showLimitedMarketSelect && (*/}
                {/*    <LimitedMarketSelect name={'markets'}*/}
                {/*                         runType={runType}*/}
                {/*                         sku={sku}*/}
                {/*                         value={contextValue.markets}*/}
                {/*                         onChange={handleChange}*/}
                {/*                         maxSelections={maxMarkets}*/}
                {/*                         categories={contextValue.categories}/>*/}
                {/*)}*/}

                {showMarketTree && !excludeRemainingMarkets && !requireRemainingMarket && (
                    <ByzzerSwitch
                        className={`${baseClassName}__remaining-market-toggle`}
                        onChange={handleIncludeRemainingChange}
                    >
                        <SelectorLabelInfo
                            sku={sku as string}
                            selectorCode={'remainingMarketToggle'}
                            tipDelay={[150, 0]}
                        />
                    </ByzzerSwitch>
                )}

                {showMarketSearch && (
                    <ByzzerMarketSearch
                        name={'markets'}
                        productSku={sku}
                        categories={contextValue.categories}
                        requiredMasterCompany={state.requiredMasterCompany}
                        requiredMarketGroup={state.requiredMarketGroup}
                        requireRemainingMarket={requireRemainingMarket}
                        showRemainingMarkets={state.showRemainingMarkets}
                        value={contextValue.markets}
                        maxSelections={maxMarkets}
                        onChange={handleChange}
                    />)}

                {showMarketTree && (
                    <MarketPicker
                        key={categoriesRefreshKey}
                        value={contextValue.markets}
                        onChange={handleChange}
                        maxSelections={maxMarkets}
                        categories={contextValue.categories}
                        requiredMasterCompany={state.requiredMasterCompany}
                        requiredMarketGroup={state.requiredMarketGroup}
                        requireRemainingMarket={requireRemainingMarket}
                        name={'markets'}
                        productSku={sku}
                        runType={runType}
                        hideTotalUSMarkets={hideTotalUSMarkets}
                        showRemainingMarkets={state.showRemainingMarkets}
                        displayMarketGroup={datatype}
                        purchasedMarketKeys={company?.purchasedMarketKeys}
                        enableParentMarket={datatype === 'cps'}
                        filterType='all'
                    />
                )}
            </FilterSection>

            <FilterSection onlyRenderIf={includeOmniTotalMarkets}>
                <SelectorLabelInfo
                    sku={sku as string}
                    selectorCode={'omniTotalMarkets'}
                    required={requireOmniTotalMarkets}
                    max={maxOmniTotalMarkets}
                    isLabelBold = {true}
                />                
                <OmniMarketSelect
                    name={'markets'}
                    value={internalValue.markets}
                    onChange={handleChange}
                    placeholder={'Select Total Markets'}
                    categories={internalValue.categories!}
                    runType={runType!}
                    allowMultiple={Number(maxOmniTotalMarkets) > 0}
                    maxSelections={maxOmniTotalMarkets}
                />
            </FilterSection>

            <FilterSection onlyRenderIf={includeOmniSubMarkets}>
                <h1 className={`filter-group-section__label`}>
                    <>
                        <SelectorLabelInfo
                            sku={sku as string}
                            selectorCode={'omniSubMarkets'}
                            required={requireOmniTotalMarkets}
                            max={maxOmniTotalMarkets}
                        />
                    </>
                </h1>
                {/* <h3>
                    The Sub-Market will be reported to understand how the performance in the sub-market is driving
                    overall performance for the total market. It's recommended that you choose the online
                    market beneath the total market for this selection. For example if you chose a Total Retailer
                    for the prior selection, here you would choose the Online market of the same retailer.
                </h3> */}
                <OmniMarketSelect
                    name={"subMarkets"}
                    value={internalValue.subMarkets}
                    onChange={handleChange}
                    placeholder={"Select Sub-Markets"}
                    categories={internalValue.categories!}
                    runType={runType!}
                    allowMultiple={Number(maxOmniSubMarkets) > 0}
                    maxSelections={maxOmniSubMarkets}
                />
            </FilterSection>
        </FilterGroup>
    )
})

MarketRunConfigFilters.displayName = 'MarketRunConfigFilters'