import {ScenarioSimulatorConfig, SimulatorCalculation, SimulatorConfig, SimulatorDatasetValue, SimulatorDatatype, SimulatorLeverConfig, SimulatorRunConfig} from "../../types/SimulatorTypes";
import { intersection } from "lodash";
import convert from "color-convert";
import { uniq } from "lodash";
import { getPPGDisplayValue } from "@/services/ppg.service";
import { convertTimePeriodToText } from "./SimulatorBuilder/components/FilterSummary/FilterSummary";
import { ByzzerSelectOption } from "@byzzer/ui-components";
import { generateSpreadsheet } from "@/services/spreadsheet.service";
import { Entries } from "type-fest";
import { ScenarioSimulatorContextValue } from "./SimulatorBuilder";
import { camelCaseToTitleCase } from "@/utils";


export function findEffectedLeverCodes(vars: string[], configs: SimulatorLeverConfig[]): string[] {

    const affectedVars: string[] = [];
    const unaffectConfigs = configs.filter(config => {
        // todo: this could be more efficient by not re-extracting the variables everytime, but this will be called
        // so few times it doesn't really matter
        if (intersection(vars, extractFormulaVars(config.formula)).length) {
            affectedVars.push(config.code);
            return false;
        }
        return true;
    });
    if (affectedVars.length) {
        return [...vars, ...findEffectedLeverCodes(affectedVars, unaffectConfigs)];
    }

    return [...vars, ...affectedVars];
}

export function extractFormulaVars(formula?: string): string[] {
    return uniq(formula?.match(/\$\$?\w+/g)?.filter(v => !v.startsWith('$$')).map(v => v.replace(/^\$\$?/, '')) ?? []);
}


// input formatters
const decimalInputFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    useGrouping: false
});

const integerInputFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    useGrouping: false
});

const percentInputFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    useGrouping: false
});

// display formatters
const decimalDisplayFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
});

const integerDisplayFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});

const currencyDisplayFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

const percentDisplayFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
});

export function extractOverrideVars(formula?: string): string[] {
    return uniq(formula?.match(/\$\$\w+/g)?.map(v => v.replace(/^\$\$?/, '')) ?? []);
}

export function isNumeric(value: string): boolean {
    return /^-?[0-9]*\.?[0-9]*$/.test(value);
}

export function rawValueToDisplay(datatype: SimulatorDatatype, value: number | undefined): string {


    if(value === undefined || isNaN(value as number)) return '';

    switch (datatype) {
        case "integer":
            return integerDisplayFormatter.format(value);
        case "currency":
            return currencyDisplayFormatter.format(value);
        case "percent":
            // @ts-ignore
            return percentDisplayFormatter.format(value);
        case "decimal":
        default:
            return decimalDisplayFormatter.format(value);
    }
}

export function rawValueToInput(datatype: SimulatorDatatype, value: number | undefined): string {

    if(value === undefined || isNaN(value as number)) return '';

    switch (datatype) {
        case "integer":
            return integerInputFormatter.format(value);
        case "percent":
            // @ts-ignore
            return percentInputFormatter.format(value);
        case "currency":
        case "decimal":
        default:
            return decimalInputFormatter.format(value);
    }
}

export function inputToRawValue(datatype: SimulatorDatatype, input: string): number | undefined {

    input = input.replace(/[^\d.-]/g, '');

    const value = Number(input);

    if(input === '' || isNaN(value)) return undefined;

    switch (datatype) {
        case "integer":
            return Math.round(value);
        case "percent":
            // precision is being used here to account for floating point math issues
            return Number((value / 100).toPrecision(12));
        case "decimal":
        default:
            return value;
    }
}

export function leverToValue(datatype: SimulatorDatatype, value?: number | string): number | undefined {

    if(typeof value === 'string') {
        switch (datatype) {
            case "integer":
                value = value.replace(/[^\d-]/g, '');
                break;
            case "decimal":
            case 'percent':
                value = value.replace(/[^\d.-]/g, '');
        }
    }

    if(value === '' || isNaN(value as number)) return undefined;

    switch (datatype) {
        case "decimal":
            return Number(value);
        case "integer":
            return Math.round(Number(value));
        case "percent":
            // @ts-ignore
            return Number(parseFloat(Number(value) / 100).toPrecision(12));
    }
}

export function valueToLever(datatype: SimulatorDatatype, value?: number | string): number | string | undefined {

    if(value === '' || isNaN(value as number)) return undefined;

    switch (datatype) {
        case "decimal":
            return Number(Number(value).toFixed(2));
        case "integer":
            return Number(value);
        case "percent":
            // @ts-ignore
            return Number(parseFloat(value  * 100).toFixed(2));
    }
}


export function getTypeByCode(code: string, simulator: SimulatorConfig): SimulatorDatatype | undefined {

    const allConfigs: (SimulatorLeverConfig | SimulatorCalculation)[] = [
        ...simulator.calculations,
        ...simulator.leverConfigs
    ];
    const config = allConfigs.find(v => v.code === code);
    return config?.datatype;
}

export function getNextColor(index: number, baseColor: string = '#2D6DF6'): string {
    const hsl = convert.hex.hsl('#2D6DF6');
    hsl[0] = (hsl[0] + index * 20) % 360;
    return `#${convert.hsl.hex(hsl)}`;
}

export function createPpgGroupNameOption(name: string, ppgId: number): ByzzerSelectOption {
    return {
        display: [-1].includes(ppgId) ? name.slice(0, name.lastIndexOf(" | ")) : name, // if Default is picked, we don't normally display the fourth piped item, which is Category
        value: name
    }
}

export function getLabelAndTextValueForSimRunConfigFilter(filter: Partial<SimulatorRunConfig>): {label: string, textValue: string} { // TODO: Change this to use different parameter(s), using generic and or tuple types.  
    const filterEntries = Object.entries(filter);
    
    if (filterEntries.length > 1) {
        return {label: '', textValue: ''};
    }

    const [filterKey, filterValue] = filterEntries[0];

    const filterData: Record<keyof SimulatorRunConfig, {label: string, textValue: (data: typeof filterValue) => string}> = {
        categories: {
            label: 'Category(s)',
            textValue: function(filterValue: any): string {
                return filterValue?.join(', ') ?? '';
            }
        },
        timePeriod: {
            label: 'Time Period',
            textValue: function(filterValue: any): string {
                return convertTimePeriodToText(filterValue);
            }
        },
        brands: {
            label: 'Brand',
            textValue: function(filterValue: any): string {
                return filterValue?.join(', ') ?? '';
            }
        },
        ppgGroupNames: {
            label: 'PPG',
            textValue: function(filterValue: any): string {
                return filterValue?.join(', ') ?? '';
            }
        },
        markets: {
            label: 'Market',
            textValue: function(filterValue: any): string {
                return filterValue?.map((market) => `${market.name} ${market?.remainingMarketRunConfig?.name ? market?.remainingMarketRunConfig?.name : ''}`.trim())?.join(', ');
            }
        },
        ppgId: {
            label: 'PPG Id',
            textValue: function(filterValue: any): string {
                return getPPGDisplayValue(filterValue!);
            }
        }
    }

    const selectedFilter = filterData[filterKey];

    return {
        label: selectedFilter.label,
        textValue: selectedFilter.textValue(filterValue)
    }
}

export async function downloadSimulator({
    value: scenarioSimulatorConfig,
    state: simulatorState
}: ScenarioSimulatorContextValue): Promise<void> {
    const tableConfigs = scenarioSimulatorConfig.simulatorConfig.tableConfigs;
    const displayValues = scenarioSimulatorConfig.displayValues;
    const filterEditor = scenarioSimulatorConfig.filterEditor;
    const filterRunConfig: SimulatorRunConfig = {
        ...filterEditor.filterRunConfig,
        ppgGroupNames: [createPpgGroupNameOption(filterEditor.filterRunConfig.ppgGroupNames?.[0]!, filterEditor.filterRunConfig.ppgId!).display] // to show the PPG group with the category on the end of the string
    };
    const datatypeMap = scenarioSimulatorConfig.datatypeMap;
    const simulatorConfig = scenarioSimulatorConfig.simulatorConfig;

    if (!tableConfigs?.length || !displayValues) return;

    const adjFilterRunConfig = (Object.entries(filterRunConfig)).filter(([filterName, filterValue]) => filterName !== 'ppgId') as Entries<typeof filterRunConfig>;

    const EMPTY_CELL: any = {value: ''};

    generateSpreadsheet(
        {
            cells: [
                // Filter headers
                adjFilterRunConfig.map(([filterName, filterValue]) => ({
                    value: getLabelAndTextValueForSimRunConfigFilter({[filterName]: filterValue})?.label ?? 'N/a',
                    formatting: {
                        bold: true,
                        hAlign: 'center',
                        bgColor: '#efefef',
                        // borderBottom: true,
                    }
                })),
                // Filter values
                adjFilterRunConfig.map(([filterName, filterValue]) => ({
                    value: getLabelAndTextValueForSimRunConfigFilter({[filterName]: filterValue})?.textValue ?? 'N/a',
                    formatting: {
                        bold: false,
                        hAlign: 'left'
                    }
                })),
                [],
                [],
                [{
                    value: `Impact`,
                    colSpan: Math.max(...tableConfigs.flatMap(({columns}) => columns.length)),
                    formatting: {
                        bold: true,
                        hAlign: 'center',
                        bgColor: '#efefef',
                        // borderBottom: true,
                    }
                }],
                [],
                ...tableConfigs?.flatMap(tableConfig => [
                    // table title
                    [{
                        value: tableConfig.title,
                        colSpan: tableConfig.columns.length,
                        formatting: {
                            bold: true,
                            hAlign: 'center',
                            bgColor: '#efefef',
                            // borderBottom: true,
                        }
                    }],
                    // [],
                    // table data headers
                    tableConfig.columns.map(col => ({
                        value: col.title,
                        formatting: {
                            bold: true,
                            hAlign: 'center'
                        }
                    })),
                    // table data values
                    ...tableConfig.data.map(row => row.map((code, i) => {
                        if (i === 0) { // 0 index is row header
                            return {
                                value: code,
                                formatting: {
                                    bold: true,
                                    hAlign: 'center'
                                }
                            };
                        }
                        return {
                            value: `{$${code}}`,
                            formatting: {
                                round: true
                            }
                        };
                    })),
                ]),
                [],
                [],
                [{
                    value: `Simulation Details`,
                    colSpan: Math.max(...tableConfigs.flatMap(({columns}) => columns.length)),
                    formatting: {
                        bold: true,
                        hAlign: 'center',
                        bgColor: '#efefef',
                        // borderBottom: true,
                    }
                }],
                [],
                ...simulatorConfig.inputGroups.flatMap(inputGroup => {
                    return [
                        // Data group titles
                        [{
                            value: inputGroup.title.trim() === '' ? inputGroup.label : inputGroup.title,
                            colSpan: Math.max(...tableConfigs.flatMap(({columns}) => columns.length)),
                            formatting: {
                                bold: true,
                                hAlign: 'center',
                                bgColor: '#efefef',
                                // borderBottom: true,
                            }
                        }],
                        ...(['label-input2x', 'label-input3x'].includes(inputGroup?.layout ?? '') ? ( 
                                [
                                    // Data headers
                                    [EMPTY_CELL].concat((inputGroup?.headers ?? inputGroup?.inputs?.map(input => input.label))?.flatMap((header) => {
                                        return {
                                            value: header,
                                            formatting: {
                                                bold: true,
                                                hAlign: 'center'
                                            }
                                        }
                                    })) ?? [EMPTY_CELL],
                                    // Data values
                                    ...inputGroup.inputs.map(input => {
                                        if (input.type === 'select') {
                                            return []; // todo: this fixes the type errors for now.  Need to figure out how to handle this case if it ever comes up.
                                        }
                                        const dataCells = input.inputCodes.filter(Boolean).map(inputCode => `{$${inputCode}}`)
                                        return [
                                            {
                                                value: input.label,
                                                formatting: {
                                                    bold: true,
                                                    hAlign: 'center'
                                                }
                                            }, 
                                            ...dataCells
                                        ];
                                    })
                                ]
                            ) : (inputGroup?.layout === 'label-input-full') ? ( 
                                [
                                    // Data values
                                    inputGroup.inputs.flatMap(input => {
                                        if (input.type === 'select') {
                                            // todo: come up with a better way to handle this.  this works for nMultiplier but may need adjustments if other drop-downs are added
                                            const entries = Object.entries(simulatorState.selectLeverValues ?? {})?.[0];

                                            if (!entries?.length) return [];

                                            const leverKey = entries[0];
                                            const leverValue = entries[1];

                                            const leverDisplayValue = input.options.find(option => option.value === leverValue)?.display ?? '';

                                            return [
                                                {
                                                    value: camelCaseToTitleCase(leverKey),
                                                    formatting: {
                                                        bold: true,
                                                        hAlign: 'center'
                                                    }
                                                },
                                                leverDisplayValue
                                            ]
                                        }
                                        return []; // todo: Need to figure out how to handle this case if it ever comes up.
                                    })
                                ]
                            ) : (
                                [
                                    // Data headers
                                    inputGroup.inputs.map(input => input.label).map(header => ({
                                        value: header,
                                        formatting: {
                                            bold: true,
                                            hAlign: 'center'
                                        }
                                    })) ?? [EMPTY_CELL],
                                    // Data values
                                    inputGroup.inputs.flatMap(input => {
                                        if (input.type === 'select') {
                                            return []; // todo: this fixes the type errors for now.  Need to figure out how to handle this case if it ever comes up.
                                        }
                                        const dataCells = input.inputCodes.filter(Boolean).flatMap(inputCode => `{$${inputCode}}`);
                                        return dataCells;
                                    })
                                ]
                            )
                        ), //  : []
                        []
                    ]
                })
            ]
        }, 
        displayValues,
        datatypeMap,
        {},
        simulatorConfig
    )
}