import React, { useContext } from "react";
import classnames from 'classnames';
import './Simulator.scss';
import { SimulatorInputLevers } from "./SimulatorInputLevers";
import { StaticValues } from "./StaticValues";
import OutcomeGraph, { SimulatorChart } from "./SimulatorChart/SimulatorChart";
import { SimulatorValues } from "@/types/SimulatorTypes";
import { SimulatorOutputTables } from "./SimulatorOutputTables";
import { ScenarioSimulatorContext, ScenarioSimulatorState } from "@/views/simulators";
import AutoSizer from "react-virtualized-auto-sizer";
import { ValueManager } from "@/views/simulators/SimulatorValueManager";

export type SimulatorProps = {
    name?: string;
    onCollapse: () => void;
    valueManager: ValueManager;
}

export const Simulator = ({
    onCollapse,
    name,
    valueManager,
    ...props
}: SimulatorProps) => {

    const baseClassName = 'byz-simulator';

    const { 
        value: { 
            simulatorConfig,
            staticValues,
            displayValues,
            datatypeMap,
            sku
        }, 
        onChange: onContextChange 
    } = useContext(ScenarioSimulatorContext);

    const handleInputLeverChange = (e: ByzzerChangeEvent<SimulatorValues> & {state?: ScenarioSimulatorState}): void => {
        onContextChange?.(
            'displayValues', 
            e.value, 
            e.state
        );
    }

    const chartTitlesForSkus = { // TODO: Add chart title to SimStudio to include in configuration. 
        'sim_promo': '% Impact of Promo Event',
        'sim_pricing': '$ Impact of Price Change',
    }

    const handleCollapse = (): void => {
        onCollapse();
    }

    return (
        <div className={classnames(`${baseClassName}`)}>
            <section className={classnames(`${baseClassName}__inputs-section`)}>
                <div className={classnames(`${baseClassName}__inputs-title`)}>
                    Scenario
                </div>
                <div className={classnames(`${baseClassName}__inputs-viewport`)}>
                    <AutoSizer>
                        {({height, width}) => (
                            <div className={classnames(`${baseClassName}__inputs-wrapper`)} style={{height}}>
                                <div className={classnames(`${baseClassName}__inputs-scenario`)}>
                                    <SimulatorInputLevers
                                        name={"displayValues"}
                                        onChange={handleInputLeverChange}
                                        onCollapse={handleCollapse}
                                        valueManager={valueManager}
                                    />
                                </div>
                                <div className={classnames(`${baseClassName}__inputs-static-values`)}>
                                    <StaticValues 
                                        name={"staticValues"}
                                        simulatorConfig={simulatorConfig}
                                        datatypeMap={datatypeMap}
                                        value={staticValues as Record<string, number | undefined>}
                                    />
                                </div>
                            </div>    
                        )}
                    </AutoSizer>
                </div>
            </section>
            <section className={classnames(`${baseClassName}__outcome-section`)}>
                <div className={classnames(`${baseClassName}__outcome-title`)}>
                    Outcome
                </div>
                <div className={classnames(`${baseClassName}__inputs-viewport`)}>
                    <AutoSizer>
                        {({height, width}) => (
                            <div className={classnames(`${baseClassName}__outcome-wrapper`)} style={{height}}>
                                <div className={classnames(`${baseClassName}__outcome-graph`)}>
                                    <p className={classnames(`${baseClassName}__outcome-graph-title`)}>
                                        {chartTitlesForSkus[sku]}
                                    </p>
                                    <SimulatorChart 
                                        name={"chartValues"}
                                        value={displayValues}
                                        chartConfig={simulatorConfig?.chartConfig}
                                        datatypeMap={datatypeMap}
                                        className={`${baseClassName}__chart`}
                                    />
                                </div>
                                <div className={classnames(`${baseClassName}__outcome-table`)}>
                                    <SimulatorOutputTables
                                        name={"outputTables"}
                                        value={displayValues}
                                        tableConfigs={simulatorConfig?.tableConfigs}
                                        datatypeMap={datatypeMap}
                                    />
                                </div>
                            </div>
                        )}
                    </AutoSizer>
                </div>
            </section>
        </div>
    );

};

export default Simulator;

Simulator.displayName = 'Simulator';