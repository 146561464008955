import './DodProductFilterBuilder.scss';
import React, { ReactNode, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import union from 'lodash/union';
import { ByzzerChangeEventHandler } from '@byzzer/ui-components';
import { DodSummedSelectionEditor } from '@/components/DodConfigEditor/common';
import { DodFilterType, DodProductFilterType } from '@/components/DodConfigEditor/types';
import { DodPanel, DodPanelRef } from '@/components/DodConfigEditor/common/DodPanel';
import { confirm } from '@/components/form/ByzzerModal';
import { isCharacteristicFilter, isMarketResetRequired, isPpgFilter } from '@/components/DodConfigEditor/common/utils';
import { DodProductFilterPreview } from './DodProductFilterPreview';
import { DodProductFilterValuePicker } from './DodProductFilterValuePicker';
import { DodProductFilterTypePicker } from './DodProductFilterTypePicker';
import { useUser } from '@/contexts/UserContext';
import { useDodService } from '@/services/dodPresets.service';
import { useMarketService } from '@/services/market.service';
import { DodPreset } from '@/types/ApiTypes';
import {
    DodCharacteristicFilter,
    DodCharacteristicFilterMap,
    DodFilter,
    DodFilters,
    DodPpgFilter,
    DodPpgFilterMap,
    SummedSelection,
} from '@/types/DodRun';
import { useDodWizard } from '../../DodRunConfigWizard/DodWizardContext';
import { RunConfigMarket } from '@/types/ReportRun';
import useAllExceptProductFields from '@/utils/useAllExceptProductFields';
import { getExcludedFields } from '@/utils/extractUtil';
import { useCategoryService } from '@/services/category.service';
import { uniq } from 'lodash';
import { MarketPickerNode } from '@/components/MarketPicker/MarketPickerNode';

const baseClassName = 'dod-product-filter-builder';

export type DodProductFilterBuilderProps = {
    className?: string;
    name?: string;
    value: DodFilters;
    tip?: ReactNode;
    onChange: ByzzerChangeEventHandler<DodFilters>;
    onValidityChange?(e: ByzzerValidityChangeEvent): void;
    resetFactSelections(filterType: string): void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

function placeholderForProductType(filterType: DodProductFilterType) {
    if (!filterType) return `search field values`;
    switch (filterType) {
        case 'upcs':
            return `Search for UPCs (must enter at least 4 digits)`;
        case 'productDescriptions':
            return `Search for product descriptions (must enter at least 4 digits)`;
        default:
            return `Search for ${filterType
                .replace(/([A-Z])/g, ' $1')
                .trim()
                .toLowerCase()}`;
    }
}

export function DodProductFilterBuilder({
    className,
    name,
    value,
    onChange,
    onValidityChange,
    tip,
    resetFactSelections,
    ...props
}: DodProductFilterBuilderProps) {
    // alias value as filters for clarity
    const filters = value;
    const { getAllCategoriesByDepartmentsAndSuperCategories } = useCategoryService();
    const { subscription, categories: userCategories } = useUser();
    const { rowColConfigs, deletePreset, setRowColConfigs, addAllExceptSelectionField, removeAllExceptSelectionField, allExceptSelectionFields } = useDodWizard();
    const { dodPresetsMap } = useDodService();
    const isFreeUser = subscription?.metadata?.isFree ?? false;
    const allowedHierarchyLevels = subscription?.metadata?.extractLimits?.allowedHierarchyLevels;
    const [filterType, setFilterType] = useState<DodFilterType<DodProductFilterType>>({
        type: 'categories',
    });
    const [valueSelectionMode, setValueSelectionMode] = useState<'single' | 'summed'>('single');
    const [summedSelection, setSummedSelection] = useState<SummedSelection | undefined>();
    const [summedSelectionIndex, setSummedSelectionIndex] = useState<number | undefined>();
    const [rawFilterText, setFilterText] = useState<string>('');
    const filterText = useDeferredValue(rawFilterText);
    const singleActions = useMemo<ActionConfig[]>(
        (): ActionConfig[] => [
            {
                icon: 'summed',
                type: 'summed',
                tip: 'Create A Summed Selection',
                action() {
                    setValueSelectionMode('summed');
                },
                include: !isFreeUser,
            },
        ],
        []
    );
    const { dedupMarkets, getEnabledMarkets, getCachedMarketNodeByName, getRunConfigMarketForMarketName } = useMarketService();
    const hasSelections = useMemo<boolean>(() => {
        return Boolean(
            value.categories.values.length +
                value.categories.summedSelections.length +
                value.departments.values.length +
                value.departments.summedSelections.length +
                value.superCategories.values.length +
                value.superCategories.summedSelections.length +
                value.subcategories.values.length +
                value.subcategories.summedSelections.length +
                value.brands.values.length +
                value.brands.summedSelections.length +
                value.parentCompanies.values.length +
                value.parentCompanies.summedSelections.length +
                value.upcs.values.length +
                value.upcs.summedSelections.length +
                value.productDescriptions.values.length +
                value.productDescriptions.summedSelections.length +
                value.manufacturers.values.length +
                value.manufacturers.summedSelections.length +
                Object.keys(value.characteristics).length +
                Object.keys(value.characteristics).length +
                Object.keys(value.customCharacteristics).length +
                Object.keys(value.customCharacteristics).length
        );
    }, [value]);
    const { categories: allExceptProductFieldsCategories } = useAllExceptProductFields(
        filters,
        allExceptSelectionFields
    );
    const selectionLimit = useMemo<number>(() => {
        return isFreeUser && filterType.type !== 'subcategories' ? 1 : Infinity;
    }, [isFreeUser, filterType]);
    const values = useMemo<string[] | 'all'>(() => {
        if (isCharacteristicFilter(filterType) || isPpgFilter(filterType)) {
            return value[filterType.type][filterType.data?.id]?.values ?? [];
        }

        return value[filterType.type]?.values ?? [];
    }, [value, filterType.type, filterType.data]);
    const valuePanelRef = useRef<DodPanelRef>(null);
    const currentSummedSelectionsRef = useRef<string[] | 'all'>([]);
    const applyBtnClickRef = useRef<boolean>(false);
    // this is used to refresh the value picker component
    const [valuePickerStateRefresh, setValuePickerStateRefresh] = useState<number>(1);

    const categories = useMemo<string[]>(() => {
        if (
            filters.categories.values === 'all' ||
            filters.categories.summedSelections.some((ss) => ss.values === 'all')
        ) {
            return getAllCategoriesByDepartmentsAndSuperCategories(filters.departments, filters.superCategories);
        } else {
            return allExceptProductFieldsCategories;
        }
    }, [filters, allExceptProductFieldsCategories]);

    useEffect(() => {
        const hasValues = Boolean(value.categories.values.length + value.categories.summedSelections.length);

        onValidityChange?.({
            name,
            isValid: hasValues,
        });
    }, [value.categories.values, value.categories.summedSelections]);

    useEffect(() => {
        setFilterText('');
    }, [filterType]);

    function removeMarketPreset() {
        deletePreset('market');
    }

    function handleFilterChange(e: ByzzerChangeEvent<DodFilters>) {
        onChange?.({
            name,
            value: e.value,
        });
    }

    function isSameSummedSelections() {
        const selectionValues = summedSelection?.values || [];
        const currentSelection = currentSummedSelectionsRef.current;

        if (Array.isArray(currentSelection) && Array.isArray(selectionValues)) {
            return isEqual([...currentSelection].sort(), [...selectionValues].sort());
        }

        return currentSelection === selectionValues;
    }

    async function handleFilterTypeChange(e) {
        if (valueSelectionMode === 'summed' && !isSameSummedSelections() && !applyBtnClickRef.current) {
            const result = await confirm({
                content:
                    'You are navigating to a different field. Do you want to add your summed products to selection first?',
                yesLabel: 'Yes',
                noLabel: 'No',
            });
            if (result) return;
            resetNavigateAwayState();
        }

        if (e.value.type === 'mySavedProducts') {
            onChange({ name, value: e.value.data });
        } else {
            setFilterType(e.value);
            reset();
        }
    }

    function getValidInvalidMarketDetails(
        dedupAllCategories: string[],
        summedCategories: string[],
        nonSummedCategories: string[]
    ) {
        const selectedMarkets: RunConfigMarket[] = dedupMarkets(
            filters.markets.values.concat(filters.markets.summedSelections.map((sumMrkt) => sumMrkt.values).flat())
        );
        const enabledMarkets: RunConfigMarket[] = getEnabledMarkets(
            selectedMarkets,
            dedupAllCategories.filter((val) => val !== ''),
            true,
            Boolean(summedCategories.length),
            summedCategories.filter((val) => val !== ''),
            nonSummedCategories.filter((val) => val !== ''),
            valueSelectionMode === 'summed'
        ) as RunConfigMarket[];
        const partiallyApprovedMarkets = enabledMarkets
            .map((mrkt) =>
                getCachedMarketNodeByName(
                    mrkt.name,
                    dedupAllCategories.filter((val) => val !== ''),
                    true,
                    Boolean(summedCategories.length),
                    summedCategories,
                    nonSummedCategories,
                    valueSelectionMode === 'summed'
                )
            )
            .filter((mrkt) => mrkt?.hasPartialApproval)
            .map((mrkt) => mrkt?.name);

        const validMarkets: string[] = enabledMarkets.map((mrkt) => mrkt.name);
        const invalidMarkets: string[] = selectedMarkets
            .filter((mrkt) => !validMarkets.includes(mrkt.name))
            .map((mrkt) => mrkt.name);

        return {
            validMarkets,
            invalidMarkets,
            partiallyApprovedMarkets
        };
    }

    async function handleSummedSelectionChange(e: ByzzerChangeEvent<SummedSelection>) {
        resetNavigateAwayState();

        const requiresMarketReset = isMarketResetRequired(filterType, filters);
        let dedupAllCategories: string[] = [];
        if (
            e.value.values === 'all' ||
            filters.categories.summedSelections.some((ss) => ss.values === 'all') ||
            filters.categories.values === 'all'
        ) {
            dedupAllCategories = getAllCategoriesByDepartmentsAndSuperCategories(
                filters.departments,
                filters.superCategories
            );
        } else {
            dedupAllCategories = uniq(
                e.value.values.concat(
                    filters.categories.summedSelections
                        .map((sum) => sum.values)
                        .concat(filters.categories.values)
                        .flat()
                )
            );
        }
        const summedCategories: string[] =
            e.value.values === 'all' || filters.categories.summedSelections.some((ss) => ss.values === 'all')
                ? getAllCategoriesByDepartmentsAndSuperCategories(filters.departments, filters.superCategories)
                : uniq(e.value.values.concat(filters.categories.summedSelections.map((sum) => sum.values).flat()));
        const nonSummedCategories: string[] =
            filters.categories.values === 'all'
                ? getAllCategoriesByDepartmentsAndSuperCategories(filters.departments, filters.superCategories)
                : filters.categories.values;

        const { validMarkets, invalidMarkets } = getValidInvalidMarketDetails(
            dedupAllCategories,
            summedCategories,
            nonSummedCategories
        );
        const invalidMarketNodes: MarketNode[] = invalidMarkets.map(
            (mrkt) =>
                getCachedMarketNodeByName(
                    mrkt,
                    dedupAllCategories,
                    true,
                    Boolean(summedCategories.length),
                    summedCategories,
                    nonSummedCategories,
                    valueSelectionMode === 'summed'
                ) as MarketNode
        );
        if (requiresMarketReset && invalidMarkets.length && !(await confirmMarketReset(invalidMarketNodes))) return;

        const updates = { ...value };
        if (requiresMarketReset) {
            updates.markets = {
                values: value.markets.values.filter((mrkt) => !invalidMarkets.includes(mrkt.name)),
                summedSelections: value.markets.summedSelections.map((sumMrkt) => ({
                    ...sumMrkt,
                    values: sumMrkt.values.filter((mrkt) => !invalidMarkets.includes(mrkt.name)),
                })),
            };
            removeMarketPreset();
        }

        setSummedSelection(e.value);
        const updatedValue = value[filterType.type];
        let filter: DodFilter | DodCharacteristicFilterMap | DodPpgFilterMap;

        if (isPpgFilter(filterType)) {
            filter = updatedValue as DodPpgFilterMap;
            const { id, displayName } = filterType.data;
            const ppg = filter[id] ?? {
                ppg: filterType.data.id,
                displayName,
                values: [],
                summedSelections: [],
            };
            if (summedSelectionIndex === undefined) {
                ppg.summedSelections = [...ppg.summedSelections, e.value];
            } else {
                ppg.summedSelections = ppg.summedSelections.map((v, i) => {
                    return i === summedSelectionIndex ? e.value : v;
                });
            }
            filter[id] = ppg;
        } else if (isCharacteristicFilter(filterType)) {
            filter = updatedValue as DodCharacteristicFilterMap;
            const { id, displayName } = filterType.data;
            const characteristic = filter[id] ?? {
                characteristic: filterType.data.code,
                displayName,
                values: [],
                summedSelections: [],
            };
            if (summedSelectionIndex === undefined) {
                characteristic.summedSelections = [...characteristic.summedSelections, e.value];
            } else {
                characteristic.summedSelections = characteristic.summedSelections.map((v, i) => {
                    return i === summedSelectionIndex ? e.value : v;
                });
            }
            filter[id] = characteristic;
        } else {
            filter = updatedValue as DodFilter<string[] | 'all'>;
            if (summedSelectionIndex === undefined) {
                filter.summedSelections = [...filter.summedSelections, e.value];
            } else {
                filter.summedSelections = filter.summedSelections.map((v, i) => {
                    return i === summedSelectionIndex ? e.value : v;
                });
            }
        }

        onChange({
            name,
            value: {
                ...value,
                [filterType.type]: filter,
                ...updates,
            },
        });
        setSummedSelectionIndex(undefined);
        setSummedSelection(undefined);
        setValueSelectionMode('single');

        // ensure 'stack' is unchecked/false for product configurations
        const isStacked = rowColConfigs.find((config) => config.dim === filterType.type && config.stack);
        if (isStacked) {
            setRowColConfigs((prevConfigs) =>
                prevConfigs.map((config) => (config.dim === filterType.type ? { ...config, stack: false } : config))
            );
        }

        // remove all except selection field
        removeAllExceptSelectionField(
            ['characteristics', 'customCharacteristics', 'ppgs'].includes(filterType.type)
                ? filterType.data.id
                : filterType.type
        );

    }

    function handleSummedSelectionCancel() {
        reset();
    }

    async function handleFilterValueChange(e: ByzzerChangeEvent<string[] | 'all'>) {
        const requiresMarketReset = isMarketResetRequired(filterType, filters);
        const requireMarketUpdate = filters.markets.values.length > 0 && filterType.type === 'categories';
        let dedupAllCategories: string[] = [];
        if (e.value === 'all' || filters.categories.summedSelections.some((ss) => ss.values === 'all')) {
            dedupAllCategories = getAllCategoriesByDepartmentsAndSuperCategories(
                filters.departments,
                filters.superCategories
            );
        } else {
            dedupAllCategories = uniq(
                e.value.concat(filters.categories.summedSelections.map((sum) => sum.values).flat())
            );
        }

        const summedCategories: string[] = filters.categories.summedSelections.some((ss) => ss.values === 'all')
            ? getAllCategoriesByDepartmentsAndSuperCategories(filters.departments, filters.superCategories)
            : uniq(filters.categories.summedSelections.map((sum) => sum.values).flat());
        const nonSummedCategories: string[] =
            e.value === 'all'
                ? getAllCategoriesByDepartmentsAndSuperCategories(filters.departments, filters.superCategories)
                : e.value;

        const { invalidMarkets, partiallyApprovedMarkets } = getValidInvalidMarketDetails(
            dedupAllCategories,
            summedCategories,
            nonSummedCategories
        );
        const partiallyApprovedSummedMarketNames = filters.markets.summedSelections
            .flatMap(sumMrkt => sumMrkt.values)
            .map(v => v.name)
            .filter(name => partiallyApprovedMarkets.includes(name));
        const isPartiallyApprovedSummedMarket = partiallyApprovedSummedMarketNames.length > 0;

        const invalidMarketNodes: MarketNode[] = union(invalidMarkets || [], partiallyApprovedSummedMarketNames || []).map(
            (mrkt) =>
                getCachedMarketNodeByName(
                    mrkt,
                    dedupAllCategories,
                    true,
                    Boolean(summedCategories.length),
                    summedCategories,
                    nonSummedCategories,
                    valueSelectionMode === 'summed'
                ) as MarketNode
        );

        if (
            requiresMarketReset &&
            (invalidMarkets.length || isPartiallyApprovedSummedMarket) &&
            !(await confirmMarketReset(invalidMarketNodes))
        ) {
            setValuePickerStateRefresh((val) => val + 1);
            return;
        }

        const updates = { ...value };
        if(requireMarketUpdate) {
            // update partially approved markets values for updated categories
            updates.markets.values = updates.markets.values.map((mrkt) =>
                getRunConfigMarketForMarketName(
                    mrkt.name,
                    dedupAllCategories,
                    true,
                    Boolean(summedCategories.length),
                    summedCategories,
                    nonSummedCategories,
                    valueSelectionMode === 'summed'
                ) as RunConfigMarket
            );
        }
        if (requiresMarketReset) {
            updates.markets = {
                values: value.markets.values.filter((mrkt) => !invalidMarkets.includes(mrkt.name)),
                summedSelections: value.markets.summedSelections.map((sumMrkt) => ({
                    ...sumMrkt,
                    values: sumMrkt.values.filter(
                        (mrkt) => !invalidMarkets.includes(mrkt.name)
                    )
                })),
            };
            removeMarketPreset();
        }
        if(isPartiallyApprovedSummedMarket){
            updates.markets.summedSelections = updates.markets.summedSelections.map((sumMrkt) => ({
                ...sumMrkt,
                values: sumMrkt.values.filter((mrkt) => !partiallyApprovedMarkets.includes(mrkt.name))
            })).filter(sumMrkt => sumMrkt.values.length > 0);
        }

        if (isCharacteristicFilter(filterType)) {
            const { id, displayName } = filterType.data;
            if (updates[filterType.type][id]) {
                updates[filterType.type][id].values = e.value;
            } else {
                updates[filterType.type][id] = {
                    characteristic: id,
                    displayName,
                    values: e.value,
                    summedSelections: [],
                } as DodCharacteristicFilter;
            }

            if (
                updates[filterType.type][id]?.values?.length === 0 &&
                updates[filterType.type][id]?.summedSelections?.length === 0
            ) {
                delete updates[filterType.type][id];
            }
        } else if (isPpgFilter(filterType)) {
            const { id, displayName } = filterType.data;
            if (updates[filterType.type][id]) {
                updates[filterType.type][id].values = e.value;
            } else {
                updates[filterType.type][id] = {
                    ppg: id,
                    displayName,
                    values: e.value,
                    summedSelections: [],
                } as DodPpgFilter;
            }

            if (
                updates[filterType.type][id]?.values?.length === 0 &&
                updates[filterType.type][id]?.summedSelections?.length === 0
            ) {
                delete updates[filterType.type][id];
            }
        } else {
            updates[filterType.type].values = e.value;
        }

        onChange({
            name,
            value: {
                ...value,
                ...updates,
            },
        });

        if ((filterType.type === 'upcs' || filterType.type === 'brands') && e.value.length === 0) {
            resetFactSelections?.(filterType.type);
        }
    }

    function handleEditSummedSelection(filterType: DodFilterType, index: number) {
        setValueSelectionMode('summed');
        setSummedSelectionIndex(index);
        setFilterType(filterType as any);
        if (isCharacteristicFilter(filterType) || isPpgFilter(filterType)) {
            const summedSelectionValues = value[filterType.type][filterType.data?.id].summedSelections[index];
            setSummedSelection(summedSelectionValues);
            currentSummedSelectionsRef.current = summedSelectionValues?.values || [];
        } else {
            const summedSelectionValues = value[filterType.type].summedSelections[index];
            setSummedSelection(summedSelectionValues);
            currentSummedSelectionsRef.current = summedSelectionValues?.values || [];
        }
    }

    function handleDeleteSummedSelection(filterType: DodFilterType, index: number) {
        reset();
    }

    function handleDeleteSummedSelectionValue(filterType: DodFilterType, index: number) {}

    async function handlePresetSelect(e: ByzzerChangeEvent<DodPreset>): Promise<void> {
        const requiresMarketReset = isMarketResetRequired(filterType, filters);
        const excludedFields = getExcludedFields(e.value.values);
        let dedupAllCategories: string[] = [];
        if (
            e.value.values.categories?.values === 'all' ||
            e.value.values.categories?.summedSelections.some((ss) => ss.values === 'all')
        ) {
            dedupAllCategories = getAllCategoriesByDepartmentsAndSuperCategories(
                e.value.values.departments!,
                e.value.values.superCategories!
            );
        } else {
            dedupAllCategories = uniq(
                e.value.values.categories?.values.concat(
                    e.value.values.categories?.summedSelections.map((sum) => sum.values).flat()
                )
            );
        }

        const summedCategories: string[] = e.value.values.categories?.summedSelections.some((ss) => ss.values === 'all')
            ? getAllCategoriesByDepartmentsAndSuperCategories(
                  e.value.values.departments!,
                  e.value.values.superCategories!
              )
            : uniq(e.value.values.categories?.summedSelections.map((sum) => sum.values).flat());
        const nonSummedCategories: string[] =
            e.value.values.categories?.values === 'all'
                ? getAllCategoriesByDepartmentsAndSuperCategories(
                      e.value.values.departments!,
                      e.value.values.superCategories!
                  )
                : e.value.values.categories?.values!;

        const { validMarkets, invalidMarkets } = getValidInvalidMarketDetails(
            dedupAllCategories,
            summedCategories,
            nonSummedCategories
        );
        const invalidMarketNodes: MarketNode[] = invalidMarkets.map(
            (mrkt) =>
                getCachedMarketNodeByName(
                    mrkt,
                    dedupAllCategories,
                    true,
                    Boolean(summedCategories.length),
                    summedCategories,
                    nonSummedCategories,
                    valueSelectionMode === 'summed'
                ) as MarketNode
        );
        if (requiresMarketReset && invalidMarkets.length && !(await confirmMarketReset(invalidMarketNodes))) return;
        let { timePeriods, categories, ...productFilters } = e.value.values as DodFilters;

        const validatedCategories: DodFilter = {
            values:
                categories.values === 'all' ? 'all' : categories.values.filter((item) => userCategories.includes(item)),
            summedSelections: categories.summedSelections.map((category) => {
                return {
                    ...category,
                    values:
                        category.values === 'all'
                            ? 'all'
                            : category.values.filter((item) => userCategories.includes(item)),
                };
            }),
        };

        onChange({
            name,
            value: {
                ...value,
                ...(productFilters as DodFilters),
                categories: validatedCategories,
                markets: {
                    values: value.markets.values.filter((mrkt) => !invalidMarkets.includes(mrkt.name)),
                    summedSelections: value.markets.summedSelections.map((sumMrkt) => ({
                        ...sumMrkt,
                        values: sumMrkt.values.filter((mrkt) => !invalidMarkets.includes(mrkt.name)),
                    })),
                },
            },
        });
        if(excludedFields.length) {
            addAllExceptSelectionField(excludedFields);
        }
        removeMarketPreset();

        if(e.value.displayName.toLowerCase() === 'my default products'){
            const excludedFields = dodPresetsMap.excludedFields;
            addAllExceptSelectionField(excludedFields as unknown as string[]);
        }

        // ensure 'stack' is unchecked/false for product configurations when a summed selection is available
        const updatedConfigs = rowColConfigs.map((config) => {
            const isSummedSelection = productFilters[config.dim]?.summedSelections?.length;
            return config.stack && isSummedSelection && config.type === 'products'
                ? { ...config, stack: false }
                : config;
        });
        const isStacked = rowColConfigs.some((config, index) => config.stack !== updatedConfigs[index].stack);
        if (isStacked) {
            setRowColConfigs(updatedConfigs);
        }
    }

    function resetNavigateAwayState() {
        applyBtnClickRef.current = false;
        currentSummedSelectionsRef.current = [];
    }

    function reset() {
        valuePanelRef.current?.resetFilter();
        setValueSelectionMode('single');
        setSummedSelection(undefined);
        setSummedSelectionIndex(undefined);
        resetNavigateAwayState();
    }

    function resetFilterToCategories() {
        setFilterType({
            type: 'categories',
        });
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <DodProductFilterTypePicker
                className={classnames(`${baseClassName}__filters`)}
                value={filterType}
                onChange={handleFilterTypeChange}
                filters={value}
                onPresetSelect={handlePresetSelect}
                allowedHierarchyLevels={allowedHierarchyLevels}
                onNoCustomChars={resetFilterToCategories}
                onNoPpgs={resetFilterToCategories}
                showPresetWarning={hasSelections}
            />

            <DodPanel
                filterPlaceholder={placeholderForProductType(filterType.type)}
                byzRef={valuePanelRef}
                onFilterChange={setFilterText}
                expandable={true}
                name={'product-filter-picker'}
                filterType={filterType}
                trackClick={{ data: { dodWizardStep: 'product', panel: 'value picker' } }}
            >
                {valueSelectionMode === 'single' && (
                    <DodProductFilterValuePicker
                        key={filterType.type}
                        filters={filters}
                        filterType={filterType}
                        filterText={filterText}
                        value={values}
                        actions={singleActions}
                        includeActions={true}
                        onApply={handleFilterValueChange}
                        limit={selectionLimit}
                        refreshState={valuePickerStateRefresh}
                    />
                )}
                {valueSelectionMode === 'summed' && (
                    <DodSummedSelectionEditor<string[] | 'all'>
                        key={`${filterType.type}:${summedSelectionIndex}`}
                        onApply={handleSummedSelectionChange}
                        onCancel={handleSummedSelectionCancel}
                        value={summedSelection}
                        includeActions={true}
                        filterType={filterType.type}
                        dodWizardStep="product"
                    >
                        {({ onChange, values, className }) => (
                            <DodProductFilterValuePicker
                                key={`${filterType.type}:${summedSelectionIndex}`}
                                filters={filters}
                                filterType={filterType}
                                filterText={filterText}
                                value={values!}
                                onChange={(data) => {
                                    applyBtnClickRef.current = false;
                                    currentSummedSelectionsRef.current = data?.value || [];
                                    onChange(data);
                                }}
                                isSummedSelection={true}
                            />
                        )}
                    </DodSummedSelectionEditor>
                )}
            </DodPanel>
            <DodProductFilterPreview
                value={value}
                onChange={handleFilterChange}
                onEditSummedSelection={handleEditSummedSelection}
                onDeleteSummedSelection={handleDeleteSummedSelection}
                onDeleteSummedSelectionValue={handleDeleteSummedSelectionValue}
                resetFactSelections={resetFactSelections}
                inSumSelectionMode={valueSelectionMode === 'summed'}
            />
        </div>
    );
}

export async function confirmMarketReset(marketNodes: MarketNode[] = []): Promise<boolean> {
    return confirm({
        title: 'Warning',
        content: (
            <>
                <p>
                    Changing your category selections will clear the markets listed below from your market selections.
                </p>
                <p>Click cancel if you wish to keep these markets selected.</p>
                <div className={classnames(`${baseClassName}__items-list`)}>
                    {marketNodes.map((market) => (
                        <MarketPickerNode
                            key={market.path}
                            marketNode={market}
                            includeToggle={false}
                            includeSelector={false}
                            displayMarketGroup={'rms'}
                        />
                    ))}{' '}
                </div>
            </>
        ),
        yesLabel: 'Continue',
        noLabel: 'Cancel',
    });
}

export default DodProductFilterBuilder;
