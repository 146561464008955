import {useUser} from "@/contexts/UserContext";
import React, {useEffect} from "react";
import {IntercomProps, useIntercom} from 'react-use-intercom';
import {useAnalyticsEvent} from "@/analytics/AnalyticsContext";
import _ from "lodash";
import {baseAppUrl} from "@/config";

export function useIntercomAnalytics() {
    // Fixing merge conflict.  focusBrand has been renamed to brands, also needed to add canEditCategories
    const {user, subscription, categories, company, brandNotRetailingYet, brands, canEditCategories, preSales, tenantId} = useUser();
    const {update, trackEvent, boot, showNewMessages, hide, shutdown,getVisitorId} = useIntercom();
    const event = useAnalyticsEvent();
    useEffect(() => {
        if (user) {
            const {
                id,
                email,
                companyId,
                role,
                firstName,
                lastName,
                teamId,
            } = user;
            const name = `${firstName} ${lastName}`;
            const props: IntercomProps = {
                userId: String(id), // was getting type error when id was not converted to string
                name,
                email,
                company: {
                    companyId: String(companyId), // was getting type error when id was not converted to string
                    name: company?.displayName,
                    customAttributes: {
                        isFree: subscription?.metadata?.isFree,
                        subscription: subscription?.title,
                    }
                },
                customLauncherSelector: '.intercom-chat',
                customAttributes: {
                    role,
                    Categories: categories?.join(", ").toLowerCase(),
                    isSubsriptionUnlocked: canEditCategories,
                    Subscription: subscription?.metadata?.isFree === true ? 'free' : subscription?.title,
                    teamId: teamId,
                    isCompanyAdmin: ['admin', 'user'].includes(user?.role!),
                    baseurl: baseAppUrl,
                    presales: preSales ?? false,
                    subscriptionBrand : _.isEmpty(brands)?'N':'Y',
                    notSellingInRetailers: brandNotRetailingYet ? 'Y': 'N',
                    currentTenantId: tenantId // hashed tenant id
                }
            }
            // shutdown(); // shutting down the prior intercom, that launched with no user
            boot(props)
            update(props)
        } else {
            //One boot method call can be implemented
            // shutdown()
            boot({
                customLauncherSelector: '.intercom-chat',
            })
        }
        showNewMessages();
        hide();
        return shutdown;
    }, [user?.id, subscription, company?.id, brands, brandNotRetailingYet]);

    useEffect(() => {
        if (event && event.type !== 'navigation') {
            trackEvent(event.name!, event.data);
        }
    }, [event]);
}
