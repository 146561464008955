import './DashboardContent.scss';
import classnames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import { ByzzerMask } from "@/components/ByzzerMask/ByzzerMask";
import { LinksGroup } from '@/components/LinksGroup';

const baseClassName = 'dashboard-content-body';

export type DashboardContentProps = {
    title?: ReactNode;
    subTitle?: ReactNode,
    links?: {
        url: string;
        text: string;
        disabled?: boolean;
    }[],
    className?: string;
    extras?: ReactNode;
    exact?: boolean;
    replace?: boolean;
    children,
    loading?: boolean;
    staticContentBody?: boolean;
    numberTag?: ReactNode;
    onLinkClick?: Function;
}

export function DashboardContent({
                                     title,
                                     subTitle,
                                     links,
                                     className,
                                     extras,
                                     exact,
                                     replace,
                                     children,
                                     loading,
                                     staticContentBody = false,
                                     numberTag,
                                     onLinkClick,
                                     ...props
                                 }: DashboardContentProps) {

    const [requiresHeader, setRequiresHeader] = useState(true);

    useEffect(() => {
        setRequiresHeader(Boolean(title || subTitle || links?.length || extras));
    }, [title, subTitle, links, extras])

    useEffect(() => {

    }, [requiresHeader])

    return (
        <div className={"dashboard-content"}>
            <div className={classnames(baseClassName, {
                'dashboard-content-body-static': staticContentBody
            })} {...props}>
                {requiresHeader && (
                    <header className={`${baseClassName}__heading`}>
                        <div className={`${baseClassName}__titles`}>
                            <div className={`${baseClassName}__title-wrapper`}>
                                <div className={`${baseClassName}__title`}>{title}</div>
                                {numberTag}
                            </div>
                            {subTitle && <div className={`${baseClassName}__subtitle`}>{subTitle}</div>}
                        </div>
                        {Boolean(links?.length) && (
                            <LinksGroup
                                links={links!}
                                orientation={'row'} // defaults to 'row', so this isnt necessary, just for example purpose
                                replace={replace}
                                exact={exact}
                                onLinkClick={onLinkClick}
                            />
                        )}
                        {extras}
                    </header>
                )}
                <div className={classnames(`${baseClassName}__view`, className)}>
                    <ByzzerMask show={loading} loading={loading}/>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default DashboardContent;
DashboardContent.displayName = 'DashboardContent';
