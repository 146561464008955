import { useEffect } from "react";
import useState from 'react-usestateref';
import classnames from 'classnames';
import './SimulatorBuilder.scss';
import {FilterEditor, Simulator} from "./components";
import { ScenarioSimulatorContext, ScenarioSimulatorContextValue, ScenarioSimulatorState } from "./ScenarioSimulatorContext";
import { ScenarioSimulatorConfig, SimulatorValues, SimulatorRunConfig, SimulatorInsightFilters, SimulatorInputGroup, SimulatorTableConfig, SimulatorChartValueConfig, SimulatorInputConfigBase, SimulatorInputConfigSelect } from "../../../types/SimulatorTypes";
import {useTenantApi} from "@/hooks/useTenantApi";
import { ByzzerMask } from "@/components/ByzzerMask/ByzzerMask";
import { ScenarioSimulatorConfigOptions } from "@/pages/SimulatorConfigEditor";
import { ByzzerButton, ByzzerSelectOption, WizardStepMessageOptions } from "@byzzer/ui-components";
import FilterSummary from "./components/FilterSummary/FilterSummary";
import nyanCat from '@/animations/nyan-cat.json';
import { downloadSimulator } from "../utils";
import { ValueManager } from "../SimulatorValueManager";
import { calculateYtdWeeksForDate } from "@/utils/timeperiodUtil";
import { useUser } from "@/contexts/UserContext";
import { TimePeriod } from "@/types/ReportRun";
import { TrackClick } from "@/analytics";

export type SimulatorBuilderProps = {
    onChange?: (e: ByzzerChangeEvent<any>) => void;
    sku?: any;
    datasetSku?: any;
    simulatorConfigOptions: ScenarioSimulatorConfigOptions;
    title: string;
    defaultFilterRunConfig: SimulatorRunConfig;
}

export function handleFlattenSelectInputs(ig: SimulatorInputGroup) {
    return (ig => ig.inputs.filter(input => input.type === 'select'))(ig)
}

export const SimulatorBuilder = ({
    onChange,
    sku,
    title,
    datasetSku,
    defaultFilterRunConfig,
    simulatorConfigOptions: {
        filterEditor: { filterConfigOptions },
        simulatorConfig
    },
    ...props
}: SimulatorBuilderProps) => {

    const { getSimulatorDatasetValues } = useTenantApi();

    const baseClassName = 'byz-simulation-builder';
    const nyanCatChance = 0.04;

    const defaultChartConfig = { type: "bar", values: [], layout: {} };
    const defaultInputGroups = [{ 
        layout: 'label-input3x',
        collapseMessage: "",
        headers: ['', '', ''],
        inputs: [],
        title: "",
        tip: ""
    }];

    const [busy, setBusy] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<WizardStepMessageOptions | undefined>();
    const [showNyanCat, setShowNyanCat] = useState<boolean>(Math.random() < nyanCatChance);
    const [valueManager, setValueManager] = useState<ValueManager>(new ValueManager());
    const { maxDataDates } = useUser();

    // financialKeywords is a temporary workaround and should be replaced via a SimStudio configuration update.  A flag will be needed in the config somewhere
    // It allows portions of the simulator to hide when financials is toggled to collapse, if at least one of these keywords is found in the label/code
    const financialKeywords = ['profit', 'cost'];

    const defaultScenarioSimulatorContextValue: ScenarioSimulatorContextValue = {
        value: {
            sku,
            filterEditor: {
                filterConfigOptions: filterConfigOptions ?? [],
                filterRunConfig: defaultFilterRunConfig,
            },
            simulatorConfig: {
                datasetId: simulatorConfig.datasetId,
                title: simulatorConfig?.title ?? title ?? '',
                calculations: simulatorConfig?.calculations ?? [],
                chartConfig: simulatorConfig?.chartConfig ?? defaultChartConfig,
                chartValues: [],
                inputGroups: simulatorConfig?.inputGroups ?? defaultInputGroups,
                leverConfigs: simulatorConfig?.leverConfigs ?? [],
                staticValuesConfig: simulatorConfig?.staticValues ?? [],
                tableConfigs: simulatorConfig?.tableConfigs ?? [],
                dataset: simulatorConfig?.dataset,
                maxPromoWeeks: undefined
            },
            staticValues: undefined, 
            displayValues: undefined,
            datatypeMap: [
                ...(simulatorConfig?.leverConfigs ?? []),
                ...(simulatorConfig?.calculations ?? []), 
                ...(simulatorConfig?.dataset ?? [])
            ].reduce((result, {code, datatype}) => ({
                ...result,
                [code]: datatype ?? 'decimal'
            }), {})
        },
        state: {
            visibleSection: 'filterEditor', // tbd
            filtersEdited: undefined,
            collapsed: true
        },
        onChange(
            name: keyof ScenarioSimulatorConfig, 
            value: SimulatorRunConfig | SimulatorValues, 
            state?: ScenarioSimulatorState
        ): void {
            if (name === 'filterEditor') {
                setContextValue((current) => {
                    const timePeriod = (value as SimulatorRunConfig)?.timePeriod;
                    const maxPromoWeeks = calculateMaxPromoWeeks(timePeriod!, maxDataDates);
                    const newContextValue: ScenarioSimulatorContextValue = {
                        ...current,
                        value: {
                            ...current.value,
                            filterEditor: {
                                ...current.value.filterEditor,
                                filterRunConfig: value as SimulatorRunConfig
                            },
                            simulatorConfig: {
                                ...current.value.simulatorConfig,
                                maxPromoWeeks
                            }
                        },
                        state: {
                            ...current.state,
                            filtersEdited: true
                        }
                    };

                    return newContextValue;
                });
                setValidationMessage(undefined);
            }
            else if (name === 'displayValues') {
                setContextValue((current) => {
                    const newContextValue = { // TODO: See if this needs refinement
                        ...current,
                        value: {
                            ...current.value,
                            displayValues: value as SimulatorValues,
                        },
                        ...(state ? { 
                            state: {
                                ...current.state,
                                ...state
                            } 
                        } : {})
                    };
                    return newContextValue;
                });
            }
        },
    }

    function calculateMaxPromoWeeks(timePeriod: TimePeriod, maxDataDates: MaxDataDates) {
        let maxPromoWeeks: number = 52;

        if (timePeriod?.type === 'week_ending') {
            maxPromoWeeks = +timePeriod?.period?.weeks!;
        } else if (timePeriod?.type === 'relative') {
            if (['Year to Date', 'Year-to-Date']?.includes(timePeriod?.period!)) {
                maxPromoWeeks = calculateYtdWeeksForDate(maxDataDates?.rms!);
            } else {
                maxPromoWeeks = +timePeriod?.period?.replace(/\D/g,'')!;
            }
        }

        return maxPromoWeeks;
    }

    const getValidSelectOptions = (input: SimulatorInputConfigBase & SimulatorInputConfigSelect, valMgr: ValueManager): ByzzerSelectOption[] => {
        const options = input.options;

        const optionValues = options.map(opt => opt.value);

        const optionsWithRawValues = valMgr.getRawValuesForCodes(optionValues) ?? [];

        const response = Object.entries(optionsWithRawValues)
            .filter(([optCode, optVal]) => optCode === input?.default || Number(optVal) > Number(input?.visibilityThreshold))
            .map(([optCode]) => options.find(({value}) => value === optCode)!);

        return response;
    };

    const shouldShowSelectInput = (selectInput: SimulatorInputConfigBase & SimulatorInputConfigSelect, valueManager: ValueManager): boolean => {
        if (selectInput?.type !== 'select') return true;

        const optionsCount = getValidSelectOptions(selectInput, valueManager)?.length;

        if (selectInput?.default) {
            return optionsCount >= 2 // todo: See if this can/should be added to inputGroups items config
        }

        return optionsCount >= 1 // todo: See if this can/should be added to inputGroups items config
    }

    const handleSelectInputGroupReduce = ( // this filters out select inputs that don't meet visibilityThreshold, also filtering their options as need.  other input types are passed through
        accum: SimulatorInputGroup[],
        inputGroup: SimulatorInputGroup, index, arr,
        additionalParams: {
            valueManager: ValueManager
        }
    ): SimulatorInputGroup[] => {
        const { valueManager } = additionalParams;

        const shouldShowInputGroup = inputGroup.inputs.every(input => input.type !== 'select' || shouldShowSelectInput(input, valueManager));

        if (shouldShowInputGroup) {
            const inputGroupWithSelectInputOptionsMapped = {
                ...inputGroup,
                inputs: inputGroup.inputs.map(input => {
                    if (input.type === 'select') {
                        const validOptions = getValidSelectOptions(input, valueManager);
                        return {
                            ...input,
                            options: validOptions
                        }
                    }
                    return input;
                })
            }
            return [...accum, inputGroupWithSelectInputOptionsMapped];
        }
        return accum;
    }

    const handleInitialInputGroupsReduce = ( // this just filters out collapsible inputs if collapsed when simulator loads
        accum: SimulatorInputGroup[], 
        inputGroup: SimulatorInputGroup, index, arr, 
        additionalParams: {
            collapsed?: boolean
        }
    ) => {
        const collapsed = additionalParams?.collapsed ?? !contextValueRef?.current?.state?.collapsed;
        const visibleLevers = inputGroup.inputs.filter(input => !(input?.collapsible && collapsed));
        const someLeversVisible = Boolean(visibleLevers?.length);

        return [
            ...accum,
            ...(someLeversVisible ? [{ ...inputGroup, inputs: visibleLevers }] : [])
        ]
    }

    const [contextValue, setContextValue, contextValueRef] = useState<ScenarioSimulatorContextValue>(() => {

        const { inputGroups, tableConfigs, chartConfig: { values, ...chartConfig }, ...simulatorConfig } = defaultScenarioSimulatorContextValue.value.simulatorConfig;

        const defaultCollapsed = defaultScenarioSimulatorContextValue.state.collapsed;

        const selectInputs = inputGroups.flatMap(handleFlattenSelectInputs);
        const defaultSelectLeverValues = Object.fromEntries(selectInputs.map(input => [input.inputCode, input.default]));

        return {
            ...defaultScenarioSimulatorContextValue,
            value: {
                ...defaultScenarioSimulatorContextValue.value,
                simulatorConfig: {
                    ...simulatorConfig,
                    inputGroups: inputGroups?.reduce((...args) => handleInitialInputGroupsReduce(...args, {collapsed: defaultCollapsed}), [] as SimulatorInputGroup[]),
                    tableConfigs: tableConfigs?.map((...args) => handleTableConfigsMap(...args, defaultCollapsed)),
                    chartConfig: {
                        ...chartConfig,
                        values: values?.filter((...args) => handleChartConfigValuesFilter(...args, defaultCollapsed)),
                    },
                    maxPromoWeeks: calculateMaxPromoWeeks(defaultScenarioSimulatorContextValue.value.filterEditor.filterRunConfig.timePeriod!, maxDataDates)
                }
            },
            state: {
                ...defaultScenarioSimulatorContextValue.state,
                selectLeverValues: defaultSelectLeverValues
            }
        }
    });

    useEffect(() => {
        setShowNyanCat(Math.random() < nyanCatChance);
    }, [contextValueRef.current.state?.filtersEdited]);

    useEffect(() => {
        const { 
            value: { simulatorConfig, staticValues },
            onChange
        } = contextValue;

        if (simulatorConfig.dataset && staticValues) {
            const paramsForNewValueManager = {
                datasetConfigs: simulatorConfig.dataset,
                calculations: simulatorConfig.calculations,
                leverConfigs: simulatorConfig.leverConfigs,
                staticValues: staticValues
            }
            const newValueManager = new ValueManager(paramsForNewValueManager);
            setValueManager(newValueManager);
            setContextValue((currentContext) => ({
                ...currentContext,
                value: {
                    ...currentContext.value,
                    simulatorConfig: {
                        ...currentContext.value.simulatorConfig,
                        inputGroups: currentContext.value.simulatorConfig.inputGroups.reduce((...args) => handleSelectInputGroupReduce(...args, {valueManager: newValueManager}), [] as SimulatorInputGroup[])
                    }
                }
            }));
            onChange?.(
                'displayValues', 
                newValueManager.getRawValues()
            );
        }
    }, [contextValue.value.staticValues]);

    function handleModifyFilters() {
        setContextValue((currentContext) => ({
            ...currentContext,
            state: { // for dev only, will remove
                ...contextValue?.state,
                visibleSection: 'filterEditor',
                filtersEdited: false
            }
        }))
    }


    function handleTableConfigsMap(tableConfig: SimulatorTableConfig, index, arr, initialCollapsed?: boolean) {
        const collapsed = initialCollapsed ?? !contextValueRef?.current?.state?.collapsed;
        return {
            ...tableConfig,
            data: tableConfig.data.filter((rowData) => !(Boolean(financialKeywords.filter((finWord) => rowData[0]?.toLowerCase().includes(finWord))?.length) && collapsed))
        }
    }

    function handleChartConfigValuesFilter(val: SimulatorChartValueConfig, index, arr, initialCollapsed?: boolean) {
        const collapsed = initialCollapsed ?? !contextValueRef?.current?.state?.collapsed;
        return !(Boolean(financialKeywords.filter((finWord) => (val?.label ?? val?.code)?.toLowerCase().includes(finWord))?.length) && collapsed)
    }

    const handleCollapse = () => {

        const newCollapsed = !contextValueRef.current.state.collapsed;

        const {
            value: {
                simulatorConfig: {
                    inputGroups: originalInputGroups, 
                    tableConfigs: originalTableConfigs, 
                    chartConfig: {
                        values: originalChartConfigValues
                    }
                }
            }
        } = defaultScenarioSimulatorContextValue;

        setContextValue((currentValue) => ({
            ...currentValue,
            state: {
                ...currentValue?.state,
                collapsed: newCollapsed
            },
            value: {
                ...currentValue.value,
                simulatorConfig: {
                    ...currentValue.value.simulatorConfig,
                    inputGroups: originalInputGroups // todo: these two reduce operations can be combined at some point. just gets a bit messy and tricky trying to pass in valueManager/collapsed
                        .reduce((...args) => handleInitialInputGroupsReduce(...args, {collapsed: newCollapsed}), [] as SimulatorInputGroup[])
                        .reduce((...args) => handleSelectInputGroupReduce(...args, {valueManager}), [] as SimulatorInputGroup[]), // this reduce happens here once valueManager is created, since it's needed for filtering.
                    tableConfigs: originalTableConfigs?.map(handleTableConfigsMap),
                    chartConfig: {
                        ...currentValue.value.simulatorConfig.chartConfig,
                        values: originalChartConfigValues?.filter(handleChartConfigValuesFilter),
                    }
                }
            }
        }))
    }

    async function handleStartSimulation() {
        setShowNyanCat(Math.random() < nyanCatChance);
        setBusy(true);

        if (contextValueRef.current.state?.filtersEdited === false) {
            setContextValue((currentContext) => ({
                ...currentContext,
                state: {
                    ...contextValue?.state,
                    visibleSection: 'simulator'
                }
            }))
            setBusy(false);
            return;
        }

        try {

            const standardizeFilterPayloadForAPI = (filterRunConfig: SimulatorRunConfig): SimulatorInsightFilters => {
                let newFilters: SimulatorInsightFilters = {
                    categoryNames: filterRunConfig?.categories, //  ?? "SHELF STABLE ENERGY BEVERAGES",
                    brandNames: filterRunConfig?.brands, //  ?? "RED BULL (RED BULL NORTH AMERICA INC)",
                    ppgId: filterRunConfig?.ppgId?.toString(), //  ?? "-1",
                    ppgGroupName: filterRunConfig?.ppgGroupNames?.[0], // ?? "RED BULL (RED BULL NORTH AMERICA INC) | 20 FLUID OUNCE | 1 Pack | SHELF STABLE ENERGY BEVERAGES",
                    marketName: filterRunConfig?.markets?.map((marketRunConfig) => marketRunConfig.name)?.[0], // ?? "Total Drug Stores",
                    timePeriod: filterRunConfig.timePeriod
                };
                return newFilters;
            }

            const standardizeFilters = standardizeFilterPayloadForAPI(contextValueRef.current.value.filterEditor.filterRunConfig);

            const staticValues = await getSimulatorDatasetValues(standardizeFilters, datasetSku, contextValueRef.current.value.simulatorConfig.dataset);

            setValidationMessage(undefined);

            setContextValue((currentContext) => ({
                ...currentContext,
                value: {
                    ...currentContext.value,
                    staticValues
                },
                state: {
                    ...contextValue?.state,
                    visibleSection: 'simulator', // show Simulator screen if successful
                }
            }))
        } catch (err: any) {

            switch(err.code) {
                case 'results_not_found':
                    setValidationMessage({
                        type: 'error',
                        content: (
                            <>
                                <p>There is no data for the selections you've made.</p> {/* todo: standardize with warning messages from reports , DRY */}
                                <p>Please try different selections in order to run your simulation.</p>
                            </>
                        ),
                    });
                    break;
                case 'partial_results':
                    setValidationMessage({
                        type: 'error',
                        content: (
                            <>
                                <p>There is not enough pricing & promotion data to project impacts.</p>
                                <p>Please try different selections in order to run your simulation.</p>
                            </>
                        ),
                    });
                    break;
                // default: // tbd
            }

            // for dev only, will remove this navigation
            setContextValue((currentContext) => ({
                ...currentContext,
                state: {
                    ...contextValue?.state,
                    visibleSection: 'filterEditor'
                }
            }))
        } finally {
            setBusy(false);
        }

        setBusy(false);
    }

     const handleDownload = () => {
        downloadSimulator(contextValueRef.current);
    }

    return (<>
        <div className={classnames(`${baseClassName}`)}>
            <ByzzerMask show={busy} loading={busy && !showNyanCat} lottieOptions={showNyanCat ? nyanCat as any : undefined}>
                We are preparing your simulation.
            </ByzzerMask>
            <ScenarioSimulatorContext.Provider value={contextValue}>
                {contextValue.state?.visibleSection === 'filterEditor' ? (
                    <>
                        <FilterEditor
                            { ...contextValue?.value?.filterEditor }
                            name={"filterEditor"}
                            onStartSimulation={handleStartSimulation}
                            message={validationMessage}
                            trackClick={{ data: {
                                title, 
                                sku
                            }}}
                        />
                    </>
                ) : (
                    <>
                        <FilterSummary onModifyFilters={handleModifyFilters} />
                        <Simulator 
                            name={"simulator"} 
                            onCollapse={handleCollapse} 
                            valueManager={valueManager} 
                        />

                        <div className={classnames(`${baseClassName}__controls`)}>
                            {/* <ByzzerButton onClick={handleCompleteClick}>Save Simulator</ByzzerButton> */}
                            <TrackClick name={'simulator_download_clicked'}>
                                <ByzzerButton 
                                    onClick={handleDownload}
                                    label={'Download as XLSX'}
                                />
                            </TrackClick>
                        </div>
                    </>
                )}
            </ScenarioSimulatorContext.Provider>
        </div>
    </>);

};

export default SimulatorBuilder;

SimulatorBuilder.displayName = 'SimulatorBuilder';
