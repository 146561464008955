import React from 'react';
import classNames from 'classnames';
import { ByzzerButton } from '@/components/form';
import { openTips } from '@/views/CustomCharacteristics/TipsModal';

type CharDefinationPropsType = {
    className?: string;
    onCreateChar: (value: boolean) => void;
}

export function CharDefinition({ className, onCreateChar }: CharDefinationPropsType) {
    const rootClassNames = classNames(className, 'custom-characteristics');

    async function onTipsClick() {
        openTips();
    }

    async function onUploadClick() {
        onCreateChar?.(true);
    }

    async function onUseCategoryClick() {
        onCreateChar?.(false);
    }

    return (
        <>
            <div className="custom-characteristics__subclass-description2">
                If you find yourself creating a field that should be universal but you can’t find in the existing byzzer
                characteristics, please contact customer support via chat! If we can create them as a syndicated value,
                we will.
            </div>
            <ByzzerButton
                className="custom-characteristics__subclass-tips-button"
                type="default"
                label={'Tips'}
                onClick={onTipsClick}
            />

            <div className="custom-characteristics__subclass2">
                <div className="custom-characteristics__subclass2-title">Create a new characteristic</div>
                <div className="custom-characteristics__subclass2-use-section">
                    <ByzzerButton
                        className="custom-characteristics__subclass2-use-button"
                        label={'Use characteristics'}
                        onClick={onUseCategoryClick}
                    />
                    <div className="custom-characteristics__subclass2-use-desc">
                        Use Characteristics: Use existing characteristics to create new segments that will apply to all
                        UPC&apos;s in the selected categories. <i>Updates Automatically with each data refresh</i>
                    </div>
                </div>
                <div className="custom-characteristics__subclass2-upload-section">
                    <ByzzerButton
                        className="custom-characteristics__subclass2-upload-button"
                        label={'Upload'}
                        onClick={onUploadClick}
                    />
                    <div className="custom-characteristics__subclass2-upload-desc">
                        Upload: Upload a list of specific UPC&apos;s mapped to their characteristic value.
                        <i> Requires more manual maintenance.</i>
                    </div>
                </div>
            </div>
        </>
    );
}
