import React, {Fragment, useContext, useEffect, useMemo} from "react";
import useState from 'react-usestateref';
import classnames from 'classnames';
import './SimulatorInputLevers.scss';
import { SimulatorInputConfigBase, SimulatorInputConfigSelect, SimulatorInputGroup, SimulatorInputGroupLayout, SimulatorValues } from "@/types/SimulatorTypes";
import {ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerSelect, ByzzerSelectOption} from "@byzzer/ui-components";
import { getTypeByCode } from "@/views/simulators/utils";
import { ValueManager } from "@/views/simulators/SimulatorValueManager";
import { LeverInput } from "../LeverInput";
import { ScenarioSimulatorContext, ScenarioSimulatorState } from "../../../ScenarioSimulatorContext";
import { handleFlattenSelectInputs } from "../../../SimulatorBuilder";


export type SimulatorInputLeversProps = {
    onChange?: (e: ByzzerChangeEvent<SimulatorValues> & {state?: ScenarioSimulatorState}) => void;
    onApply?: ByzzerChangeEventHandler<SimulatorValues>;
    name?: string;
    onCollapse: () => void;
    valueManager: ValueManager;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export const DEFAULT_SIM_LEVERS_LAYOUT = 'label-input3x';

export const leverLayoutsWithHeaders = ['label-input3x', 'label-input2x'];

const inputCountsByLayout: Record<SimulatorInputGroupLayout, number> = {
    "label-input": 1,
    "label-input-full": 1,
    "label-input-label-input": 1,
    "label-input2x": 2,
    "label-input3x": 3,
};

const baseClassName = 'simulator-input-levers';

export const SimulatorInputLevers = ({
    onChange,
    onApply,
    // or create ref
    name,
    onCollapse,
    valueManager,
    ...props
}: SimulatorInputLeversProps) => {

    const {
        value: {
            simulatorConfig,
            displayValues,
        },
        state: {
            collapsed,
            selectLeverValues
        }
    } = useContext(ScenarioSimulatorContext);
    const selectInputs = useMemo(() => simulatorConfig?.inputGroups.flatMap(handleFlattenSelectInputs), [simulatorConfig.inputGroups]);

    const [leverValues, setLeverValues] = useState<Record<string, number | undefined>>({});
    const [calculationCodes, setCalculationCodes] = useState<string[]>([]);

    useEffect(() => {
        if (simulatorConfig) {
            const calcCodes = simulatorConfig.calculations.map(v => v.code);
            setCalculationCodes(calcCodes ?? []);

            setLeverValues(valueManager.getRawValues());
        }
    }, [simulatorConfig?.dataset, displayValues])

    function handleChange(e: ByzzerChangeEvent<number | undefined>): void {

        valueManager.updateLever(e.name!, e.value);
        setLeverValues(valueManager.getRawValues());
        onChange?.({
            name,
            value: valueManager.getRawValues()
        });
    }

    function handleSelectChange(event: ByzzerChangeEvent<string | undefined>): void {
        const inputName = event.name!;
        const datasetCode = event?.value;

        const defaultCode = selectInputs.find(input => input.inputCode === inputName)?.default;

        const defaultLeverValue = valueManager.getCalculatedValueForCode(inputName); // build this into valueManager updateLever if no value is provided?
        const shouldUseDefault = !datasetCode || datasetCode === defaultCode;
        const newInputValue = shouldUseDefault ? defaultLeverValue : valueManager.getRawValues()[datasetCode]!;

        valueManager.updateLever(inputName, newInputValue);

        const updatedRawValues = valueManager.getRawValues();

        setLeverValues(updatedRawValues);

        onChange?.({
            name,
            value: valueManager.getRawValues(),
            state: {
                selectLeverValues: {
                    [inputName]: (shouldUseDefault ? defaultCode : datasetCode)! 
                }
            }
        });
    }

    function handleToggleCollapse() {
        onCollapse();
    }

    const ToggleCollapseElement = () => (
        <span className={classnames(`${baseClassName}__toggle-collapse-element`, {
            [`${baseClassName}__toggle-collapse-element--collapsed`]: collapsed,
            [`${baseClassName}__toggle-collapse-element--expanded`]: !collapsed,
        })}/>
    )

    const collapseLeversText = useMemo(() => collapsed ? 'Expand to include financials & profit impact' : 'Hide financials & profit impact', [collapsed]);

    return (
        <div className={classnames(`${baseClassName}`)} {...props}>
            {/* <header>
                <h1 className={'simulator-preview__title'}>
                    Levers
                </h1> // todo: add support for tool tips here
            </header> */}
            <main className={`${baseClassName}__groups`}>
                {simulatorConfig?.inputGroups.map(({layout = DEFAULT_SIM_LEVERS_LAYOUT, tip, tipMarkdown, title, headers = [], inputs = []}, index) => {

                    const inputCount = inputCountsByLayout[layout];
                    const includeTitle = Boolean(title);
                    const includeHeaders = leverLayoutsWithHeaders.includes(layout);
                    const indexArray = Array.from({length: inputCount});

                    return (
                        <div key={title} className={classnames(
                            `${baseClassName}__group`, 
                            `${baseClassName}__group--${layout}`, 
                            `${baseClassName}__group--show-separator`
                        )}>
                            {includeTitle && (
                                <h2 className={`${baseClassName}__group-title`}>
                                    <span>{title}</span>
                                </h2>
                            )}
                            {includeHeaders && Boolean(headers?.length) && (<>
                                <div/> {/* This creates an empty column for headers, it's not here by accident */}
                                {indexArray.map((_, i) => (
                                    <div key={i} className={`${baseClassName}__header`}>
                                        {headers[i]}
                                    </div>
                                ))}
                            </>)}
                            {inputs?.map((input, i) => {
                                return (
                                    <Fragment key={i}>
                                        <div className={`${baseClassName}__label`}>
                                            <span>{input?.label}:</span>
                                        </div>
                                        {indexArray.map((_, i) => {
                                            if (input?.type === 'select') {
                                                const inputCode = input?.inputCode;

                                                return (
                                                    <ByzzerSelect
                                                        key={inputCode}
                                                        name={inputCode}
                                                        value={selectLeverValues?.[inputCode]}
                                                        onChange={handleSelectChange}
                                                        options={input.options}
                                                        className={classnames(`${baseClassName}__select`)}
                                                    />
                                                )
                                            } else if (!input?.type || input?.type === 'text') {
                                                const inputCodes = input?.inputCodes ?? [];
                                                const code = inputCodes[i];

                                                return (
                                                    <LeverInput 
                                                        key={code} 
                                                        className={classnames(`${baseClassName}__input`)}
                                                        name={code}
                                                        type={getTypeByCode(code, simulatorConfig)}
                                                        onChange={handleChange}
                                                        value={leverValues[code]}
                                                        readOnly={calculationCodes.includes(code) || !code}
                                                        totalValue={leverValues['totalWeeks']} // todo: Integrate this into SimStudio so that weeks isnt hard-coded
                                                        maxValue={code.includes('week') ? simulatorConfig?.maxPromoWeeks : undefined} // todo: Integrate this into SimStudio so that weeks isnt hard-coded
                                                    />
                                                )
                                            }
                                        })}
                                    </Fragment>
                                )
                            })}
                        </div>
                    )
                })}
            </main>
            <div className={classnames(`${baseClassName}__toggle-collapse`)} onClick={handleToggleCollapse}>
                {ToggleCollapseElement()} {collapseLeversText}
            </div>
        </div>
    );

};

export default SimulatorInputLevers;

SimulatorInputLevers.displayName = 'SimulatorInputLevers';