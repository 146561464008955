import React, {useRef, useState} from 'react';
import './CustomCharacteristics.scss';
import { openCharEditor } from "@/views/CustomCharacteristics/editor/CharEditor";
import { CharDefinition } from './CharacteristicsDefination';
import DashboardContent from "@/components/dashboard/DashboardContent";
import CustomCharacteristicsGrid from './CustomCharacteristicsGrid';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { GridApi } from 'ag-grid-community';
interface CustomGridApi extends GridApi {
    refresh: () => void;
}

export function CustomCharacteristics() {

    const listRef = useRef<CustomGridApi | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    async function refreshPpgs() {
        if(listRef.current) {
            listRef.current.refresh();
        }
    }

    async function onCreateChar(allowUpload) {
        if (await openCharEditor({
            allowUpload
        } as any)) {
            console.log("Upload Successfull")
            refreshPpgs();
        }
    }
    const subTitle = <p>Create your own characteristics which you can use throughout the byzzer platform. This feature allows you to run reports using customized segments that are unique to your business.</p>

    return (
        <>
            <ByzzerMask show={isLoading} loading={true}>
                Loading your characteristics...
            </ByzzerMask>
            <DashboardContent className={'custom-characteristics'} title={'Custom Characteristics'} subTitle={subTitle}>
                <CharDefinition onCreateChar={onCreateChar} />
                <CustomCharacteristicsGrid setIsLoading={setIsLoading} />
            </DashboardContent>
        </>
    );
}

export default CustomCharacteristics;
