import React, {useRef, useState} from 'react';
import './ppg.scss';
import {PPGDefinition} from './PPGDefinition';
import {PPGReportList} from './PPGReportList';
import {openPPGEditor} from './editor/PPGEditor';
import PPGGrid from './PPGGrid';
import { DashboardContent } from '@/components/dashboard';
import { GridApi } from 'ag-grid-community';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';

interface CustomGridApi extends GridApi {
    refresh: () => void;
}

export function PPGs() {
    const gridRef = useRef<CustomGridApi | null>(null); // Ref for PPGGrid
    const [isLoading, setIsLoading] = useState(false);

    async function refreshPpgs() {
        if (gridRef.current) {
            gridRef.current.refresh(); // Refresh the grid
        }
    }

    async function onCreatePPG(allowUpload) {
        if (
            await openPPGEditor({
                allowUpload,
            } as any)
        ) {
            refreshPpgs();
        }
    }

    return (
        <>
            <ByzzerMask show={isLoading} loading={isLoading}>
                Loading your PPGs...
            </ByzzerMask>
            <DashboardContent className={'ppgs'}>
                <div className={'ppgs__container'}>
                    <PPGDefinition className={'ppgs__card'} onCreatePPG={onCreatePPG} />
                    <PPGReportList className={'ppgs__card'} />
                </div>
                <PPGGrid ref={gridRef} setIsLoading={setIsLoading} />
            </DashboardContent>
        </>
    );
}
