import './DodFilterTypePicker.scss';
import React, {Fragment, ReactNode, useImperativeHandle, useState} from "react";
import classnames from "classnames";
import {ByzzerChangeEventHandler, ByzzerMessagePopUp, ByzzerTipIcon} from '@byzzer/ui-components';
import {nanoid} from "nanoid";
import {useDodService} from "@/services/dodPresets.service";
import {confirm, openModal} from "@/components/form";
import {DodPreset, DodPresetSummary, DodPresetType} from '@/types/ApiTypes';
import {DodPanel} from "@/components/DodConfigEditor/common/DodPanel";
import {cloneDeep} from "lodash";
import {useDodWizard} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext";
import { getRegexForSearchString } from '@/utils';

const baseClassName = 'dod-filter-value-type-picker';

type LoadOptionsFunction<FiltersType, FilterTypeType> =
    (filtersConfig: FiltersType) => Promise<DodFilterTypeOption<FilterTypeType>[]> | DodFilterTypeOption<FilterTypeType>[];

export type DodFilterTypeOptionGroup<FiltersType, FilterTypeType> = {
    key?: string;
    label: ReactNode;
    tip?: ReactNode;
    /**
     Used for filtering matching the group.
     This must be defined if the label isn't a string or filtering will not work.
     */
    filterText?: string;
    options?: DodFilterTypeOption<FilterTypeType>[];
    loadOptions?: LoadOptionsFunction<FiltersType, FilterTypeType>;
    disabled?(filtersConfig?: FiltersType): Promise<boolean> | boolean;
    disabledTip?: ReactNode;
    delete?(filterVal: FilterTypeType): Promise<void> | void;
}

type ExpandableDodFilterTypeOptionGroup<FiltersType, FilterTypeType> =
    DodFilterTypeOptionGroup<FiltersType, FilterTypeType> & {
    matchingOptions?: DodFilterTypeOption<FilterTypeType>[];
    expanded: boolean;
    loading?: boolean;
};

export type DodFilterTypeOption<FilterTypeType> = {
    key?: string;
    label: ReactNode;
    tip?: ReactNode;
    value: FilterTypeType;
    /**
     * Used for filtering matching the option.
     * This must be defined if the label isn't a string or filtering will not work.
     */
    filterText?: string;
    disabled?: (filtersConfig?: any) => Promise<boolean> | boolean,
    disabledTip?: ReactNode;
}

export type DodFilterTypePickerRef = {
    reloadOptionGroups(): void;
    reloadOptionGroupsByKeys(keys: string[], force?: boolean): void;
    reloadOptionGroupsByIndexes(indexes: number[]): void;
}

/**
 * @defaultExpandAll 
 * if set to true, all available options folders will be collapsed
 * if false, existing functionality
 */
export type DodFilterTypePickerProps<FiltersType, FilterTypeType> = {
    name?: string;
    className?: string;
    filters?: FiltersType;
    children?: ReactNode | ReactNode[];
    initialOptionGroups: DodFilterTypeOptionGroup<FiltersType, FilterTypeType>[];
    value: FilterTypeType;
    onChange: ByzzerChangeEventHandler<FilterTypeType>;
    tipDelay?: number | [number | null, number | null];
    pickerRef?: React.Ref<DodFilterTypePickerRef>;
    valueMatcher?(valueA: FilterTypeType, valueB: FilterTypeType): boolean;
    title?: string;
    presetType?: DodPresetType;
    presetGroupLabel?: string;
    presetGroupTip?: ReactNode;
    onPresetSelect: ByzzerChangeEventHandler<DodPreset>;
    filterPlaceholder?: string;
    showPresetWarning?: boolean;
    onValidatePreset?: (preset: DodPreset) => boolean;
    defaultExpandAll?: boolean;
};


const DEFAULT_TIP_DELAY: [number, number] = [500, 0]

function defaultValueMatcher(a: any, b: any): boolean {
    return a === b;
}


export function DodFilterTypePicker<FiltersType, FilterTypeType>({
                                                                     className,
                                                                     name,
                                                                     initialOptionGroups,
                                                                     tipDelay = DEFAULT_TIP_DELAY,
                                                                     value,
                                                                     onChange,
                                                                     filters,
                                                                     pickerRef,
                                                                     title,
                                                                     valueMatcher = defaultValueMatcher,
                                                                     presetType,
                                                                     presetGroupLabel,
                                                                     presetGroupTip,
                                                                     onPresetSelect,
                                                                     showPresetWarning = false,
                                                                     onValidatePreset,
                                                                     defaultExpandAll = false,
                                                                     ...props
                                                                 }: DodFilterTypePickerProps<FiltersType, FilterTypeType>) {

    const {dodPresetsMap, deletePreset, getPreset} = useDodService();
    const {setPreset, runConfig} = useDodWizard();
    const [filterText, setFilterText] = useState<string>('');
    const [savedSelectionsExpanded, setSavedSelectionsExpanded] = useState<boolean>(false);
    const [optionGroups, setOptionGroups] = useState<ExpandableDodFilterTypeOptionGroup<FiltersType, FilterTypeType>[]>(
        initialOptionGroups?.map((group, index) => {

            const options = group.options?.map(option => ({
                ...option,
                key: option.key ?? nanoid(5),
                filterText: option.filterText ?? option.label as string
            }));
            const firstHasOptions = Boolean(initialOptionGroups[0].options);
            return {
                ...group,
                options,
                filterText: group.filterText ?? group.label as string,
                key: group.key ?? nanoid(5),
                expanded: defaultExpandAll ? Boolean(options) :  index === 0 ? firstHasOptions : !firstHasOptions && Boolean(options),
                matchingOptions: options
            }
        })
    );
    const noFilterApplied: boolean = !filterText;
    const noMatches: boolean = Boolean(filterText) && !optionGroups.some(config => Boolean(config.matchingOptions?.length));

    useImperativeHandle(pickerRef, () => ({
        async reloadOptionGroups() {
            optionGroups.forEach(group => loadOptions(group))
        },
        async reloadOptionGroupsByIndexes(indexes: number[]) {
            indexes.forEach(index => loadOptions(optionGroups[index]));
        },
        async reloadOptionGroupsByKeys(keys: string[], force = false) {
            optionGroups.filter(({key}) => keys.includes(key!)).forEach(group => loadOptions(group, force));
        }
    }), [optionGroups, filters]);

    function toggleSavedSelectionExpansion() {
        setSavedSelectionsExpanded(value => !value);
    }

    async function handleGroupClick(group: ExpandableDodFilterTypeOptionGroup<FiltersType, FilterTypeType>) {

        if (group.disabled?.(filters)) return;

        group.expanded = !group.expanded;
        setOptionGroups(groups => groups.map(config => ({
            ...config,
            expanded: config.key === group.key ? group.expanded : config.expanded
        })));

        if (!group.options) {
            loadOptions(group);
        }
    }

    function handleOptionClick(node: DodFilterTypeOption<FilterTypeType>) {
        onChange({
            name,
            value: node.value!
        });
    }

    function handleFilterChange(value) {
        const matcher = getRegexForSearchString(value);
        setOptionGroups(state => state.map(config => ({
            ...config,
            matchingOptions: config.options?.filter(({filterText}) => matcher.test(filterText!))
        })))
    }

    function clearOptions(group: DodFilterTypeOptionGroup<FiltersType, FilterTypeType>) {

        setOptionGroups(groups => groups.map(config => ({
            ...config,
            options: config.key === group.key ? undefined : config.options,
            matchingOptions: config.key === group.key ? undefined : config.matchingOptions
        })));
    }

    async function loadOptions(group: ExpandableDodFilterTypeOptionGroup<FiltersType, FilterTypeType>, force: boolean = false) {
        if (!group.loadOptions) return;

        // clear the options, this will cause the options to reload the next time the group is expanded
        clearOptions(group);

        // only actively reload an expanded node, since we cleared the options above the node will be reloaded the
        // nex time it's expanded.
        if (!group.expanded && force !== true) return;

        setOptionGroups(groups => groups.map(g => ({
            ...g,
            loading: g.key === group.key ? true : g.loading,
        })));
        const options = (await group.loadOptions(filters!)).map(option => ({
            ...option,
            key: option.key ?? nanoid(5),
            filterText: option.filterText ?? option.label as string
        }));
        setOptionGroups(groups => groups.map(g => {

            if (g.key !== group.key) return g;

            return {
                ...g,
                options,
                matchingOptions: options,
                loading: false
            }
        }));
    }

    async function handleSavedSelectionClick(preset: DodPresetSummary | DodPreset): Promise<void> {
        const dodPreset = (preset as DodPreset).values ? cloneDeep(preset as DodPreset) : await getPreset(preset.id, runConfig);
        if(onValidatePreset && !onValidatePreset(dodPreset)) {
            return
        }
        if (!showPresetWarning || await confirm({
            title: 'Warning',
            content: <>
                <p>Applying this Saved Selection will replace any selections you've already made on this step.</p>
                <p>Do you want to continue?</p>
            </>,
            yesLabel: 'Yes',
            noLabel: 'No'
        })) {
            setPreset(presetType!, preset);
            onPresetSelect?.({
                name,
                value: dodPreset
            });
        }
    }

    async function handleSavedSelectionDelete(id: number): Promise<void> {

        openModal({
            title: 'Delete Saved Selection',
            showCloseOption: false,
            content: ({busy}) => {

                return <>
                    {busy ? (
                        <p>Removing Saved Selection...</p>
                    ) : (
                        <p>Are you sure you want to delete this Saved Selection?</p>
                    )}
                </>
            },
            actions: [{
                key: 'cancel',
                label: 'No, Cancel',
                type: 'negative',
                action({reject}) {
                    reject()
                }
            }, {
                key: 'yes',
                label: 'Yes',
                async action({resolve, setBusy}) {
                    try {
                        setBusy(true)
                        await deletePreset(id);
                        resolve(true)
                    } finally {
                        setBusy(false);
                    }
                }
            }]
        })
    }


    return <DodPanel className={classnames(baseClassName, className)} {...props} title={title}
                     emptyState={'No Matching Filters Found'}
                     isEmpty={noMatches}
                     onFilterChange={handleFilterChange}
                     trackClick={{ data: { dodWizardStep: presetType, panel: "value picker" } }}
            >

        <div className={`${baseClassName}__tree`}>
            {optionGroups.map((group, sectionIndex) => (<React.Fragment key={`option-group-${group.key}`}>
                {Boolean(noFilterApplied || group.matchingOptions?.length) && (
                    <div onClick={() => handleGroupClick(group)}
                         className={classnames(`${baseClassName}__node`, `${baseClassName}__section-node`, {
                             [`${baseClassName}__section-node--expanded`]: group.expanded,
                             [`${baseClassName}__section-node--loading`]: group.loading,
                             [`${baseClassName}__section-node--disabled`]: group.disabled?.(filters),
                         })}
                    >
                        <ByzzerMessagePopUp disabled={!group.disabled?.(filters)} tipTrigger={"hover"}
                                            tipDelay={tipDelay} tip={group.disabledTip}>
                            {group.label}
                        </ByzzerMessagePopUp>

                        {group.tip && (
                            <ByzzerTipIcon tipDelay={tipDelay} tip={<div className={`${baseClassName}__tip`}>
                                {group.tip}
                            </div>}/>
                        )}
                    </div>
                )}
                {group.expanded && group.matchingOptions?.map((option) => (
                    <Fragment key={`filter-option-${option.key}`}>
                        <div
                            className={classnames(`${baseClassName}__node`, `${baseClassName}__value-node`, {
                                [`${baseClassName}__value-node--selected`]: valueMatcher(option.value, value),
                                [`${baseClassName}__value-node--disabled`]: option?.disabled?.(filters),
                            })}
                        >
                            <ByzzerMessagePopUp
                                disabled={!option.disabled?.(filters)}
                                tipTrigger={"hover"}
                                tipDelay={tipDelay}
                                tipLocation='right'
                                tip={group.disabledTip}>
                            <span onClick={() => handleOptionClick(option)}>
                                {option.label}
                            </span>
                            </ByzzerMessagePopUp>

                            {option.tip && <ByzzerTipIcon tip={option.tip} tipDelay={tipDelay}/>}
                            {
                                group.delete && <span className={`${baseClassName}__value-node--delete`}
                                                      onClick={() => group?.delete?.(option.value)}></span>
                            }
                        </div>
                    </Fragment>
                ))}
                {/* This will applicable for all folders, if this is not the correct way then we need to fix in other way  */}
                {
                    group.expanded && !group.matchingOptions?.length && (
                        <div className={`${baseClassName}__empty`}>
                            No Available Options
                        </div>
                    )
                }
            </React.Fragment>))}
            {Boolean(presetType) && (<>
                <div onClick={() => toggleSavedSelectionExpansion()}
                     className={classnames(`${baseClassName}__node`, `${baseClassName}__section-node`, {
                         [`${baseClassName}__section-node--expanded`]: savedSelectionsExpanded
                     })}>
                    {presetGroupLabel}
                    {Boolean(presetGroupTip) && (
                        <ByzzerTipIcon tipDelay={tipDelay} tip={<div className={`${baseClassName}__tip`}>
                            {presetGroupTip}
                        </div>}/>
                    )}
                </div>
                {savedSelectionsExpanded && dodPresetsMap[presetType!]?.map((preset) => (
                    <div key={`saved-selection-${preset.id}`}
                         className={classnames(`${baseClassName}__node`, `${baseClassName}__value-node`)}>

                        <span onClick={() => handleSavedSelectionClick(preset)}>
                            {preset.displayName}
                        </span>

                        {/* assume id <= 0 are don't exist on the server so they can't be deleted*/}
                        {preset.id > 0 && (
                            <i className={`${baseClassName}__value-node--delete`}
                               onClick={() => handleSavedSelectionDelete(preset.id)}/>
                        )}
                    </div>
                ))}
            </>)}
        </div>
    </DodPanel>
}

export default DodFilterTypePicker;